import { decodeJWTToken } from './utils/jwt';

const authProvider = {
  login: async ({ jwt }: { jwt: any }) => {
    // TODO implement auto login
    localStorage.setItem('jwt', jwt);
  },
  logout: async () => {
    localStorage.removeItem('jwt');
  },
  checkAuth: async () => {
    try {
      const jwt = decodeJWTToken(localStorage.getItem('jwt') || '');
      if (jwt.exp < Date.now() / 1000) {
        localStorage.removeItem('jwt');
        return Promise.reject();
      }
    } catch (e) {
      localStorage.removeItem('jwt');
      return Promise.reject();
    }
  },
  checkError: async (error: any) => {
    const status = error.status;
    if (
      status === 401 ||
      (status === 400 && (error.body.detail || '').includes('Invalid token'))
    ) {
      localStorage.removeItem('jwt');
      return Promise.reject();
    }
  },
  getIdentity: async () => {
    const jwt = decodeJWTToken(localStorage.getItem('jwt') || '');
    return {
      id: jwt.sub,
      fullName: jwt.name,
    };
  },
  getPermissions: async () => {
    const jwt = decodeJWTToken(localStorage.getItem('jwt') || '');
    return jwt.scope;
  },
};

export default authProvider;
