import {
  Edit,
  SimpleForm,
  useRecordContext,
  useTranslate,
  NumberInput,
  useNotify,
  useRefresh,
  useRedirect,
  Toolbar,
  SaveButton,
  DeleteButton,
  ToolbarClasses,
  Labeled,
  TextField,
  useUpdate,
} from 'react-admin';
import { Box, Card, CardContent, Grid, useMediaQuery } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { PriceWithId } from './PriceWithId';
import PeriodRedirectButton from './PeriodRedirectButton';

const PriceEdit = () => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  return (
    <Edit title={<PriceTitle />} component="div" mutationMode="pessimistic">
      <Box maxWidth="50em">
        <Card>
          <SimpleForm
            toolbar={
              <Toolbar>
                <div className={ToolbarClasses.defaultToolbar}>
                  <PriceSaveButtonRedirectToPeriod />
                  <PriceDeleteButtonRedirectToPeriod />
                </div>
              </Toolbar>
            }
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} marginBottom="1rem">
                  <PeriodRedirectButton />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  display="flex"
                  flexDirection={isXSmall ? 'row' : 'column'}
                >
                  <Labeled
                    source="tier"
                    marginRight={isXSmall ? '2rem' : undefined}
                  >
                    <TextField
                      source="tier"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                    />
                  </Labeled>
                  <Labeled source="period_id" label="period_id">
                    <TextField
                      source="period_id"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="item_id"
                    label="item_id"
                    isRequired
                    fullWidth
                    helperText="resources.prices.fields.item_id.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="price"
                    isRequired
                    fullWidth
                    helperText="resources.prices.fields.price.helperText"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </SimpleForm>
        </Card>
      </Box>
    </Edit>
  );
};

const PriceTitle = () => {
  const record = useRecordContext<PriceWithId>();
  const translation = useTranslate();
  return record ? (
    <span>
      {translation('resources.prices.edit_title')} {record.id}
    </span>
  ) : null;
};

const PriceSaveButtonRedirectToPeriod = () => {
  const record = useRecordContext<PriceWithId>();

  const [update] = useUpdate();
  const { getValues } = useFormContext();

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleClick = (e: any) => {
    e.preventDefault(); // necessary to prevent default SaveButton submit logic
    const { id, ...data } = getValues();
    update(
      'prices',
      { id, data },
      {
        onSuccess: () => {
          notify('resources.prices.modify_success');
          redirect(`/periods/${record.period_id}`);
          refresh();
        },
      },
    );
  };
  return record ? <SaveButton resource="prices" onClick={handleClick} /> : null;
};

const PriceDeleteButtonRedirectToPeriod = () => {
  const record = useRecordContext<PriceWithId>();
  return record ? (
    <DeleteButton
      resource="prices"
      mutationMode="pessimistic"
      redirect={() => {
        return `periods/${record.period_id}`;
      }}
    />
  ) : null;
};

export default PriceEdit;
