import { Alert } from '@mui/material';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  List,
  NumberField,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';

const workflowFilters = [
  <NumberInput label="id" source="id" defaultValue={undefined} />,
  <SelectInput
    label="state"
    source="state"
    choices={[
      { id: 'PENDING', name: 'PENDING' },
      { id: 'PASSED', name: 'PASSED' },
      { id: 'REJECTED', name: 'REJECTED' },
    ]}
    defaultValue={undefined}
  />,
  <TextInput label="type" source="type" defaultValue={undefined} />,
  <NumberInput label="stage" source="stage" defaultValue={undefined} />,
  <DateInput label="startTime" source="startTime" defaultValue={undefined} />,
  <DateInput label="endTime" source="endTime" defaultValue={undefined} />,
  <TextInput label="info" source="info" defaultValue={undefined} />,
];

const WorkflowList = () => {
  const translation = useTranslate();
  return (
    <>
      <List filters={workflowFilters}>
        <Datagrid
          isRowSelectable={() => false}
          optimized
          bulkActionButtons={false}
        >
          <NumberField
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <DateField source="created" />
          <TextField source="type" />
          <NumberField
            source="stage"
            options={{
              useGrouping: false,
            }}
          />
          <TextField source="info" sortable={false} />
          <TextField source="state" />
          <ArrayField source="history" sortable={false}>
            <Datagrid bulkActionButtons={false}>
              <NumberField
                source="id"
                options={{
                  useGrouping: false,
                }}
              />
              <TextField source="actor" />
              <DateField source="timestamp" />
              <BooleanField source="accepted" />
              <TextField source="reason" />
              <NumberField
                source="stage"
                options={{
                  useGrouping: false,
                }}
              />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </List>
    </>
  );
};

export default WorkflowList;
