import { DataProvider } from 'ra-core';
import openAPI from '../../services/openapi/openAPI';

export default {
  // TODO: Implement paging, filtering and ordering
  getList: async (resource, params) => {
    return {
      data: [],
      total: 0,
    };
  },

  getOne: async (resource, params) => {
    const [period_id, tier] = String(params.id).split('-');
    const res = await openAPI.RegService.prices.getPricePricePeriodIdTierGet(
      Number(period_id),
      tier,
    );
    return {
      data: { ...res, id: params.id },
    };
  },

  getMany: async () => {
    return {
      data: [],
    };
  },

  getManyReference: async (resource, params) => {
    const res = await openAPI.RegService.prices.listPricesPricePeriodIdGet(
      Number(params.id),
    );
    return {
      data: res.map((r) => ({
        ...r,
        id: `${r.period_id}-${r.tier}`,
      })) as any,
      total: res.length,
    };
  },

  update: async (resource, params) => {
    const res = await openAPI.RegService.prices.updatePricePricePeriodIdTierPut(
      params.data.period_id,
      params.data.tier,
      {
        item_id: params.data.item_id,
        price: params.data.price,
      },
    );
    return {
      data: { ...res, id: params.id },
    };
  },

  updateMany: async () => new Promise(() => {}),

  create: async (resource, params) => {
    const res = await openAPI.RegService.prices.createPricePricePost({
      period_id: params.data.period_id,
      tier: params.data.tier,
      item_id: params.data.item_id,
      price: params.data.price,
    });
    return {
      data: { ...res, id: res.period_id + '-' + res.tier },
    };
  },

  delete: async (resource, params) => {
    const [period_id, tier] = String(params.id).split('-');
    const res =
      await openAPI.RegService.prices.deletePricePricePeriodIdTierDelete(
        Number(period_id),
        tier,
      );
    return {
      data: res,
    };
  },

  deleteMany: async () => new Promise(() => {}),
} as DataProvider;
