import {
  Labeled,
  SimpleForm,
  TextField,
  TextInput,
  DateTimeInput,
  Create,
  useTranslate,
  ListButton,
} from 'react-admin';
import { Alert, Box, Card, CardContent, Grid, Typography } from '@mui/material';

const PeriodCreate = () => {
  const translation = useTranslate();
  return (
    <Create
      title={<span>{translation('resources.periods.create_title')}</span>}
      component="div"
    >
      <Box maxWidth="50em">
        <Card>
          <ListButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <SimpleForm
            defaultValues={{
              start_time: new Date(),
              free_from: new Date(),
              valid_until: null,
              deleted_after: null,
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={1}>
                  <Labeled source="Id">
                    <TextField
                      source="id"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={11}>
                  <TextInput
                    source="name"
                    isRequired
                    fullWidth
                    helperText="resources.periods.fields.name.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="start_time"
                    isRequired
                    fullWidth
                    helperText="resources.periods.fields.start_time.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="free_from"
                    isRequired
                    fullWidth
                    helperText="resources.periods.fields.free_from.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="valid_until"
                    fullWidth
                    helperText="resources.periods.fields.valid_until.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="deleted_after"
                    fullWidth
                    helperText="resources.periods.fields.deleted_after.helperText"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </SimpleForm>
        </Card>
      </Box>
    </Create>
  );
};

export default PeriodCreate;
