/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PersonalRegistration } from '../models/PersonalRegistration';
import type { PersonalRegistrationCreateAdmin } from '../models/PersonalRegistrationCreateAdmin';
import type { PersonalRegistrationUpdate } from '../models/PersonalRegistrationUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PersonalRegistrationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List
     * @param schacc
     * @param subnet
     * @param limit
     * @param offset
     * @param authorization
     * @returns PersonalRegistration Successful Response
     * @throws ApiError
     */
    public listPersonalRegistrationsGet(
        schacc?: string,
        subnet?: string,
        limit: number = 50,
        offset?: number,
        authorization?: string,
    ): CancelablePromise<Array<PersonalRegistration>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/personal-registrations/',
            headers: {
                'authorization': authorization,
            },
            query: {
                'schacc': schacc,
                'subnet': subnet,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @param authorization
     * @returns PersonalRegistration Successful Response
     * @throws ApiError
     */
    public createPersonalRegistrationsPost(
        requestBody: PersonalRegistrationCreateAdmin,
        authorization?: string,
    ): CancelablePromise<PersonalRegistration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/personal-registrations/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get
     * @param uuid
     * @param authorization
     * @returns PersonalRegistration Successful Response
     * @throws ApiError
     */
    public getPersonalRegistrationsUuidGet(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<PersonalRegistration> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/personal-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update
     * @param uuid
     * @param requestBody
     * @param authorization
     * @returns PersonalRegistration Successful Response
     * @throws ApiError
     */
    public updatePersonalRegistrationsUuidPut(
        uuid: string,
        requestBody: PersonalRegistrationUpdate,
        authorization?: string,
    ): CancelablePromise<PersonalRegistration> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/personal-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param uuid
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deletePersonalRegistrationsUuidDelete(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/personal-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
