import { DataProvider } from 'ra-core';
import openAPI from '../../services/openapi/openAPI';

export default {
  // TODO: Implement paging, filtering and ordering
  getList: async () => {
    const res =
      await openAPI.RegService.reservedIpAddresses.getReservedIpsReservedGet();
    return {
      data: res.map((r) => ({
        ...r,
      })) as any,
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
  },

  getOne: async (resource, params) => {
    const res =
      await openAPI.RegService.reservedIpAddresses.getReservedIpReservedIdGet(
        Number(params.id),
      );
    return {
      data: res,
    };
  },

  getMany: async () => new Promise(() => {}),

  getManyReference: async () => new Promise(() => {}),

  update: async (resource, params) => {
    const res =
      await openAPI.RegService.reservedIpAddresses.updateReservedIpReservedIdPut(
        params.id,
        {
          address: params.data.address,
          comment: params.data.comment,
        },
      );
    return { data: res };
  },

  updateMany: async () => new Promise(() => {}),

  create: async (resource, params) => {
    const res =
      await openAPI.RegService.reservedIpAddresses.createReservedIpReservedPost(
        {
          address: params.data.address,
          comment: params.data.comment,
        },
      );
    return {
      data: res,
    };
  },

  delete: async (resource, params) => {
    const res =
      await openAPI.RegService.reservedIpAddresses.deleteReservedIpReservedIdDelete(
        Number(params.id),
      );
    return {
      data: res,
    };
  },

  deleteMany: async () => new Promise(() => {}),
} as DataProvider;
