/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegistrationCreate } from '../models/RegistrationCreate';
import type { ServerRegistration } from '../models/ServerRegistration';
import type { ServerRegistrationUpdate } from '../models/ServerRegistrationUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ServerRegistrationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Server Registrations
     * @param limit
     * @param offset
     * @param authorization
     * @returns ServerRegistration Successful Response
     * @throws ApiError
     */
    public listServerRegistrationsServerRegistrationsGet(
        limit: number = 50,
        offset?: number,
        authorization?: string,
    ): CancelablePromise<Array<ServerRegistration>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/server-registrations/',
            headers: {
                'authorization': authorization,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Server Registration
     * @param requestBody
     * @param authorization
     * @returns ServerRegistration Successful Response
     * @throws ApiError
     */
    public createServerRegistrationServerRegistrationsPost(
        requestBody: RegistrationCreate,
        authorization?: string,
    ): CancelablePromise<ServerRegistration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/server-registrations/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Server Registration
     * @param uuid
     * @param authorization
     * @returns ServerRegistration Successful Response
     * @throws ApiError
     */
    public getServerRegistrationServerRegistrationsUuidGet(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<ServerRegistration> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/server-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Server Registration
     * @param uuid
     * @param requestBody
     * @param authorization
     * @returns ServerRegistration Successful Response
     * @throws ApiError
     */
    public updateServerRegistrationServerRegistrationsUuidPut(
        uuid: string,
        requestBody: ServerRegistrationUpdate,
        authorization?: string,
    ): CancelablePromise<ServerRegistration> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/server-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Server Registration
     * @param uuid
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deleteServerRegistrationServerRegistrationsUuidDelete(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/server-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
