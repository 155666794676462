import {
  AppBar,
  LoadingIndicator,
  TitlePortal,
  ToggleThemeButton,
} from 'react-admin';
import { Box, useMediaQuery, Theme, Typography } from '@mui/material';

const CustomAppBar = () => {
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('md'),
  );
  return (
    <AppBar
      color="secondary"
      elevation={1}
      toolbar={
        <>
          <ToggleThemeButton />
          <LoadingIndicator />
        </>
      }
    >
      <TitlePortal />
      {isLargeEnough && <Typography>AdminSCH B2B</Typography>}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

export default CustomAppBar;
