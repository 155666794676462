import {
  Edit,
  Labeled,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
  DateTimeInput,
  ListButton,
  CloneButton,
  useTranslate,
} from 'react-admin';
import { RegistrationPeriod } from '../../../services/openapi/reg-service';
import { Alert, Box, Card, CardContent, Grid } from '@mui/material';

const GroupPeriodEdit = () => {
  const translation = useTranslate();
  return (
    <Edit title={<PeriodTitle />} component="div" mutationMode="pessimistic">
      <Box maxWidth="50em">
        <Card>
          <ListButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <CloneButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <SimpleForm>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={1}>
                  <Labeled source="Id">
                    <TextField
                      source="id"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={11}>
                  <TextInput
                    source="name"
                    isRequired
                    fullWidth
                    helperText="resources.group_periods.fields.name.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="start_time"
                    isRequired
                    fullWidth
                    helperText="resources.group_periods.fields.start_time.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="valid_until"
                    fullWidth
                    helperText="resources.group_periods.fields.valid_until.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="deleted_after"
                    fullWidth
                    helperText="resources.group_periods.fields.deleted_after.helperText"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </SimpleForm>
        </Card>
      </Box>
    </Edit>
  );
};

const PeriodTitle = () => {
  const record = useRecordContext<RegistrationPeriod>();
  const translation = useTranslate();
  return record ? (
    <span>
      {translation('resources.group_periods.edit_title')} {record.name}
    </span>
  ) : null;
};

export default GroupPeriodEdit;
