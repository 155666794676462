import { TranslationMessages } from 'react-admin';
import hungarianMessages from './hungarianMessages';

const customHungarianMessages: TranslationMessages = {
  ...hungarianMessages,
  pos: {
    search: 'Keresés',
    configuration: 'Beállítások',
    language: 'Nyelv',
    theme: {
      name: 'Téma',
      light: 'Világos',
      dark: 'Sötét',
    },
    menu: {
      reg: 'Regisztrációk',
      period_and_price: 'Időszakok és árak',
      dns: 'DNS',
    },
  },
  login: {
    sign_in_jwt: 'Bejelentkezés (saját JWT-vel)',
    sign_in_normal: 'Bejelentkezés (normál mód)',
  },
  utils: {
    copied_jwt: 'JWT vágolapra másolva!',
    copy_button_text: 'JWT Vágolapra',
  },
  resources: {
    alert: {
      unfinished_list_api:
        'Lista szűrés, rendezés és lapozás backend oldalról még nem elérhető. Emiatt egy-egy listázás minden elemet lekér, ezáltal leterhelve a klienst és a hálózatot. Bátorítunk hogy fejleszd velünk együtt az AdminSCH-t hogy ezek a funkciók is elérhetővé váljanak.',
      period_deletion_price:
        'Mielőtt törölnéd ezt az időszakat, törölni kell a kapcsolódó Árakat!',
    },
    personal_regs: {
      edit_title: 'Személyes regisztráció:',
      create_title: 'Személyes reg létrehozása',
      name: 'Személyes',
      fields: {
        ipv4: {
          helperText:
            'Opciók a harmadik "." kiírása után kerülnek lekérésre. Regisztrációhoz tartozó IPv4 cím. Ez kerül kiosztásra DHCP-n a megadott MAC című eszközöknek. (Lehet üres)',
        },
        ipv6: {
          helperText:
            'Regisztrációhoz tartozó IPv6 cím. Ez kerül kiosztásra DHCP-n a megadott MAC című eszközöknek. (Lehet üres)',
        },
        macs: {
          helperText:
            'MAC címek listája. DHCP engedélyezése esetén DHCP szerver konfigjába bekerülnek. Ha ez mező ki van kapcsolva, akkor csak a Radius cache-be kerülnek be, ergó csak a "port-auth" fog működni mikor csatlakozik az eszköz.',
        },
        enabled: {
          helperText:
            'Regisztráció engedélyezése. Kikapcsolása esetén Radius és DHCP synceléskor ignorálásra kerül.',
        },
        nextsrv: {
          helperText:
            'Netboot szerver IP címe. DHCP szerver ezt a címet adja meg a klienseknek.',
        },
        pxefile: {
          helperText:
            'Netboot fájl neve. Jelenleg bármilyen arhitektúra esetén ez a fájl kerül visszaadásra. Később ez a mező lehet több részre lesz szedve.',
        },
        annotation: {
          helperText:
            'Bármi egyéb valid JSON adat. Például itt van jelenleg jelezve hogy milyen licensszel lett egy-egy cím igényelve.',
        },
        period_id: {
          helperText: 'Kapcsolódó kollégista időszak ID-ja. ',
        },
        schacc: {
          helperText: 'A regisztráció tulajdonosának Schacc-ja',
        },
        tier: {
          helperText: 'Kedvezményes regisztráció kategóriája',
        },
      },
    },
    group_regs: {
      edit_title: 'Körös regisztráció:',
      create_title: 'Körös reg létrehozása',
      name: 'Körös',
      fields: {
        ipv4: {
          helperText:
            'Opciók a harmadik "." kiírása után kerülnek lekérésre. Regisztrációhoz tartozó IPv4 cím. Ez kerül kiosztásra DHCP-n a megadott MAC című eszközöknek. (Lehet üres)',
        },
        ipv6: {
          helperText:
            'Regisztrációhoz tartozó IPv6 cím. Ez kerül kiosztásra DHCP-n a megadott MAC című eszközöknek. (Lehet üres)',
        },
        macs: {
          helperText:
            'MAC címek listája. DHCP engedélyezése esetén DHCP szerver konfigjába bekerülnek. Ha ez mező ki van kapcsolva, akkor csak a Radius cache-be kerülnek be, ergó csak a "port-auth" fog működni mikor csatlakozik az eszköz.',
        },
        enabled: {
          helperText:
            'Regisztráció engedélyezése. Kikapcsolása esetén Radius és DHCP synceléskor ignorálásra kerül.',
        },
        nextsrv: {
          helperText:
            'Netboot szerver IP címe. DHCP szerver ezt a címet adja meg a klienseknek.',
        },
        pxefile: {
          helperText:
            'Netboot fájl neve. Jelenleg bármilyen arhitektúra esetén ez a fájl kerül visszaadásra. Később ez a mező lehet több részre lesz szedve.',
        },
        annotation: {
          helperText:
            'Bármi egyéb valid JSON adat. Például itt van jelenleg jelezve hogy milyen licensszel lett egy-egy cím igényelve.',
        },
        created_by: {
          helperText: 'Annak Schacc-ja aki igényelte a körös regisztrációt.',
        },
        administrators: {
          helperText:
            'Regisztrációhoz tartozó adminisztrátorok. Ezek a felhasználók látják és tudják szerkeszteni a regisztrációt. Alapból csak az adott kör körvezetője tudja szerkeszteni.',
        },
        approved: {
          helperText:
            'Jóváhagyás állapota. Regisztráció igénylése után egy RVT bírálaton esik át a regisztráció és elfogadás után ez a mező igazra vált.',
        },
        period_id: {
          helperText: 'Kapcsolódó körös időszak ID-ja. ',
        },
        group_id: {
          helperText: 'Kapcsolódó Kör PÉK ID-ja.',
        },
        justification: {
          helperText:
            'Indoklás. Például miért kell a regisztráció, milyen célra, stb.',
          emptyText: 'Never',
        },
      },
    },
    server_regs: {
      edit_title: 'Szerver regisztráció:',
      create_title: 'Szerver reg létrehozása',
      name: 'Szerver',
      fields: {
        ipv4: {
          helperText:
            'Opciók a harmadik "." kiírása után kerülnek lekérésre. Regisztrációhoz tartozó IPv4 cím. Ez kerül kiosztásra DHCP-n a megadott MAC című eszközöknek. (Lehet üres)',
        },
        ipv6: {
          helperText:
            'Regisztrációhoz tartozó IPv6 cím. Ez kerül kiosztásra DHCP-n a megadott MAC című eszközöknek. (Lehet üres)',
        },
        macs: {
          helperText:
            'MAC címek listája. DHCP engedélyezése esetén DHCP szerver konfigjába bekerülnek. Ha ez mező ki van kapcsolva, akkor csak a Radius cache-be kerülnek be, ergó csak a "port-auth" fog működni mikor csatlakozik az eszköz.',
        },
        enabled: {
          helperText:
            'Regisztráció engedélyezése. Kikapcsolása esetén Radius és DHCP synceléskor ignorálásra kerül.',
        },
        nextsrv: {
          helperText:
            'Netboot szerver IP címe. DHCP szerver ezt a címet adja meg a klienseknek.',
        },
        pxefile: {
          helperText:
            'Netboot fájl neve. Jelenleg bármilyen arhitektúra esetén ez a fájl kerül visszaadásra. Később ez a mező lehet több részre lesz szedve.',
        },
        annotation: {
          helperText:
            'Bármi egyéb valid JSON adat. Például itt van jelenleg jelezve hogy milyen licensszel lett egy-egy cím igényelve.',
        },
        last_seen: {
          helperText:
            'Utolsó alkalom mikor eszközt látta a rendszer. Ennek frissítésére egy IPwave nevű cronjob fut a hálózaton.',
          emptyText: 'Never',
        },
        warned: {
          helperText:
            'Történt-e valamilyen értesítés. (Ez akkor történik ha nem elérhető már egy ideje)',
        },
      },
    },
    periods: {
      edit_title: 'Kolis időszak:',
      create_title: 'Kolis időszak létrehozás',
      related_prices: 'Kapcsolódó árak',
      name: 'Kollégista időszak',
      fields: {
        name: {
          helperText:
            "Bármi lehet, viszont kezdődjön 'Évszám. Időszak'-al. Pl.: '2022. Nyár - Eskü van net'",
        },
        start_time: {
          helperText: 'Ettől az időponttól kezdve lehet regisztrálni ide.',
        },
        free_from: {
          helperText:
            'Innentől ingyenesen készíthető regisztráció. Érdemes az időszak 2/3-hoz igazítani.',
        },
        valid_until: {
          helperText:
            'Eddig érvényes. Ettől az időponttól kezdve Radius DB-ből és DHCP adatbázisból törlésre kerül.',
        },
        deleted_after: {
          helperText:
            'Ettől az időponttól kezdve a kapcsolódó regek törlendőnek jelölődnek. Egy K8S cronjob pedig eltakarítja ilyen regeket naponta. (expire-job)',
        },
      },
    },
    group_periods: {
      edit_title: 'Körös időszak:',
      create_title: 'Körös időszak létrehozás',
      name: 'Körös időszak',
      fields: {
        name: {
          helperText:
            "Bármi lehet, viszont kezdődjön 'Évszám. Időszak'-al. Pl.: '2022. Nyár - Eskü van net'",
        },
        start_time: {
          helperText: 'Ettől az időponttól kezdve lehet regisztrálni ide.',
        },
        valid_until: {
          helperText:
            'Eddig érvényes. Ettől az időponttól kezdve Radius DB-ből és DHCP adatbázisból törlésre kerül.',
        },
        deleted_after: {
          helperText:
            'Ettől az időponttól kezdve a kapcsolódó regek törlendőnek jelölődnek. Egy K8S cronjob pedig eltakarítja ilyen regeket naponta. (expire-job)',
        },
      },
    },
    reserved_ip_addresses: {
      create_title: 'Fenntartott cím létrehozása',
      edit_title: 'Fenntartott cím:',
      name: 'Fenntartott címek',
      fields: {
        address: {
          helperText:
            'Valid IPv4 subnet. Jelenleg csak IPv4 címeket ellenőrzünk. Például 192.168.1.0/24',
        },
        comment: {
          helperText:
            'Bármilyen megjegyzés a címről. Például melyik szervernek van fenntartva.',
        },
      },
    },
    prices: {
      name: 'Ár',
      edit_title: 'Ár szerkesztés:',
      create_title: 'Ár létrehozása',
      modify_success: 'Sikeres módosítás',
      create_success: 'Sikeres létrehozás',
      create_success_message: 'Ár sikeresen létrehozva.',
      redirect_to_period: 'Vissza az időszakra',
      create_button: 'Új ár felvétele',
      fields: {
        period_id: {
          helperText: 'Időszak ID-ja',
        },
        tier: {
          helperText: 'Belépő típusa',
        },
        item_id: {
          helperText:
            'Kapcsolódó ID külsős fizető rendszerből. Ha ebben az időszakban nem kell fizetni, akkor állítsd 0-ra.',
        },
        price: {
          helperText:
            'Ezzel a kedvezménnyel igényelt regisztráció után ezt az összeget kell majd kifizetni.',
        },
      },
    },
    workflow: {
      name: 'Workflow',
    },
    dns_zone: {
      name: 'Zónák',
    },
    dns_record: {
      name: 'Rekordok',
      create_title: 'DNS cím létrehozása',
      edit_title: 'DNS cím szerkesztés:',
      fields: {
        reg_uuid: {
          helperText:
            'DNS címhez társított IP regisztráció. Lényegi funkciója, hogy a társított reg törlésekor a DNS cím is törlődjön.',
        },
        zone_id: {
          helperText: 'Kapcsolódó zóna ID-ja. Például .sch.bme.hu.',
        },
        name: {
          helperText:
            'DNS cím neve. Például "admin" az admin.sch.bme.hu -ra oldódna fel.',
        },
        value: {
          helperText:
            'DNS cím értéke. Például egy A rekord esetén itt egy IPv4 cím szerepelhet csak.',
        },
        type: {
          helperText: 'DNS cím típusa.',
        },
        approved: {
          helperText:
            'Engedélyezett/Elfogadott-e a DNS cím. Ha igen, akkor megkapják a DNS szerverek, különben nem kerül syncelésre.',
        },
        primary_hostname: {
          helperText:
            'Elsődleges-e a cím a társított IP címhez. Ha igen, akkor a releváns PTR rekordokhoz automatikusan hozzákerül ez a DNS név. Pl.: ha maze.sch.bme.hu (A rekord) 152.66.211.122 és elsődleges, akkor synceléskor készül egy 122.211.66.152.in-addr.arpa PTR rekord hozzá.',
        },
        ttl: {
          helperText: 'DNS cím lejárati ideje.',
        },
        description: {
          helperText: 'Opcionális megjegyzés.',
        },
      },
    },
  },
};

export default customHungarianMessages;
