import * as React from 'react';
import { CheckForApplicationUpdate, Layout, LayoutProps } from 'react-admin';
import Menu from './Menu';
import CustomAppBar from './AppBar';

export const BaseLayout = ({ children, ...props }: LayoutProps) => (
  <Layout {...props} appBar={CustomAppBar} menu={Menu}>
    {children}
    <CheckForApplicationUpdate />
  </Layout>
);

export default BaseLayout;
