import { Alert } from '@mui/material';
import {
  ArrayField,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  List,
  NumberField,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import { recordTypeInputChoices } from '../../../utils/record';

const DnsFilters = [
  <NumberInput label="id" source="id" defaultValue={undefined} />,
  <TextInput label="reg_uuid" source="regUuid" defaultValue={undefined} />,
  <TextInput label="name" source="name" defaultValue={undefined} />,
  <TextInput
    label="name__icontains"
    source="nameIcontains"
    defaultValue={undefined}
  />,
  <NumberInput label="zone_id" source="zone" defaultValue={undefined} />,
  <BooleanInput label="approved" source="approved" defaultValue={undefined} />,
  <BooleanInput
    label="primary_hostname"
    source="primaryHostname"
    defaultValue={undefined}
  />,
  <SelectInput
    label="type"
    source="type"
    choices={recordTypeInputChoices}
    defaultValue={undefined}
  />,
  <NumberInput label="ttl" source="ttl" defaultValue={undefined} />,
  <NumberInput label="ttlGte" source="ttlGte" defaultValue={undefined} />,
  <NumberInput label="ttlLte" source="ttlLte" defaultValue={undefined} />,
  <TextInput label="value" source="value" defaultValue={undefined} />,
  <TextInput
    label="value__icontains"
    source="valueIcontains"
    defaultValue={undefined}
  />,
  <BooleanInput
    label="reg_uuid_is_null"
    source="regUuidIsnull"
    defaultValue={undefined}
  />,
];

const DnsList = () => {
  const translation = useTranslate();
  return (
    <>
      <List filters={DnsFilters}>
        <Datagrid
          isRowSelectable={() => false}
          optimized
          bulkActionButtons={false}
        >
          <NumberField
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <TextField source="reg_uuid" />
          <TextField source="name" />
          <NumberField
            source="zone_id"
            options={{
              useGrouping: false,
            }}
          />
          <BooleanField source="approved" />
          <BooleanField source="primary_hostname" />
          <TextField source="type" />
          <NumberField
            source="ttl"
            options={{
              useGrouping: false,
            }}
          />
          <TextField source="value" />
          <TextField source="description" />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default DnsList;
