import { Alert } from '@mui/material';
import {
  ArrayField,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  List,
  NumberField,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import { recordTypeInputChoices } from '../../../utils/record';

const ZoneFilters = [
  <NumberInput label="id" source="id" defaultValue={undefined} />,
  <TextInput label="name" source="name" defaultValue={undefined} />,
  <BooleanInput label="schOnly" source="schOnly" defaultValue={undefined} />,
  <NumberInput
    label="versionGte"
    source="versionGte"
    defaultValue={undefined}
  />,
  <NumberInput
    label="versionLte"
    source="versionLte"
    defaultValue={undefined}
  />,
  <TextInput label="masterDns" source="masterDns" defaultValue={undefined} />,
  <TextInput label="rname" source="rname" defaultValue={undefined} />,
  <NumberInput label="refresh" source="refresh" defaultValue={undefined} />,
  <NumberInput
    label="refreshGte"
    source="refreshGte"
    defaultValue={undefined}
  />,
  <NumberInput
    label="refreshLte"
    source="refreshLte"
    defaultValue={undefined}
  />,
  <NumberInput label="retry" source="retry" defaultValue={undefined} />,
  <NumberInput label="retryGte" source="retryGte" defaultValue={undefined} />,
  <NumberInput label="retryLte" source="retryLte" defaultValue={undefined} />,
  <NumberInput label="expire" source="expire" defaultValue={undefined} />,
  <NumberInput label="expireGte" source="expireGte" defaultValue={undefined} />,
  <NumberInput label="expireLte" source="expireLte" defaultValue={undefined} />,
  <NumberInput label="ttl" source="ttl" defaultValue={undefined} />,
  <NumberInput label="ttlGte" source="ttlGte" defaultValue={undefined} />,
  <NumberInput label="ttlLte" source="ttlLte" defaultValue={undefined} />,
];

const ZoneList = () => {
  const translation = useTranslate();
  return (
    <>
      <List filters={ZoneFilters} perPage={25}>
        <Datagrid
          isRowSelectable={() => false}
          optimized
          bulkActionButtons={false}
        >
          <NumberField
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <TextField source="name" />
          <BooleanField source="sch_only" />
          <NumberField
            source="version"
            options={{
              useGrouping: false,
            }}
          />
          <TextField source="master_dns" />
          <TextField source="rname" />
          <NumberField
            source="refresh"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            source="retry"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            source="expire"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            source="ttl"
            options={{
              useGrouping: false,
            }}
          />
          {/* TODO <EditButton /> */}
        </Datagrid>
      </List>
    </>
  );
};

export default ZoneList;
