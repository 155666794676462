/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupRegistrationPeriod } from '../models/GroupRegistrationPeriod';
import type { GroupRegistrationPeriodCreate } from '../models/GroupRegistrationPeriodCreate';
import type { GroupRegistrationPeriodUpdate } from '../models/GroupRegistrationPeriodUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GroupRegistrationPeriodsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Group Periods
     * @param authorization
     * @returns GroupRegistrationPeriod Successful Response
     * @throws ApiError
     */
    public listGroupPeriodsGroupPeriodsGet(
        authorization?: string,
    ): CancelablePromise<Array<GroupRegistrationPeriod>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/group-periods/',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Group Period
     * @param requestBody
     * @param authorization
     * @returns GroupRegistrationPeriod Successful Response
     * @throws ApiError
     */
    public createGroupPeriodGroupPeriodsPost(
        requestBody: GroupRegistrationPeriodCreate,
        authorization?: string,
    ): CancelablePromise<GroupRegistrationPeriod> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/group-periods/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Current Group Period
     * @param authorization
     * @returns GroupRegistrationPeriod Successful Response
     * @throws ApiError
     */
    public currentGroupPeriodGroupPeriodsCurrentGet(
        authorization?: string,
    ): CancelablePromise<GroupRegistrationPeriod> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/group-periods/current',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Group Period
     * @param periodId
     * @param authorization
     * @returns GroupRegistrationPeriod Successful Response
     * @throws ApiError
     */
    public getGroupPeriodGroupPeriodsPeriodIdGet(
        periodId: number,
        authorization?: string,
    ): CancelablePromise<GroupRegistrationPeriod> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/group-periods/{period_id}',
            path: {
                'period_id': periodId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Group Period
     * @param periodId
     * @param requestBody
     * @param authorization
     * @returns GroupRegistrationPeriod Successful Response
     * @throws ApiError
     */
    public updateGroupPeriodGroupPeriodsPeriodIdPut(
        periodId: number,
        requestBody: GroupRegistrationPeriodUpdate,
        authorization?: string,
    ): CancelablePromise<GroupRegistrationPeriod> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/group-periods/{period_id}',
            path: {
                'period_id': periodId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Group Period
     * @param periodId
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deleteGroupPeriodGroupPeriodsPeriodIdDelete(
        periodId: number,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/group-periods/{period_id}',
            path: {
                'period_id': periodId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
