import { DataProvider } from 'ra-core';
import openAPI from '../../services/openapi/openAPI';
import { convertDatesToStringsWithoutTimezone } from '../../utils/date_utils';

export default {
  // TODO: Implement paging, filtering and ordering
  getList: async () => {
    const res =
      await openAPI.RegService.groupRegistrationPeriods.listGroupPeriodsGroupPeriodsGet();
    return {
      data: res.map((r) => ({
        ...r,
      })) as any,
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
  },

  getOne: async (resource, params) => {
    const res =
      await openAPI.RegService.groupRegistrationPeriods.getGroupPeriodGroupPeriodsPeriodIdGet(
        Number(params.id),
      );
    return {
      data: res,
    };
  },

  getMany: async () => new Promise(() => {}),

  getManyReference: async () => new Promise(() => {}),

  update: async (resource, params) => {
    const res =
      await openAPI.RegService.groupRegistrationPeriods.updateGroupPeriodGroupPeriodsPeriodIdPut(
        Number(params.id),
        convertDatesToStringsWithoutTimezone(params.data),
      );
    return {
      data: res,
    };
  },

  updateMany: async () => new Promise(() => {}),

  create: async (resource, params) => {
    const res =
      await openAPI.RegService.groupRegistrationPeriods.createGroupPeriodGroupPeriodsPost(
        convertDatesToStringsWithoutTimezone(params.data) as any,
      );
    return {
      data: res,
    };
  },

  delete: async (resource, params) => {
    const res =
      await openAPI.RegService.groupRegistrationPeriods.deleteGroupPeriodGroupPeriodsPeriodIdDelete(
        Number(params.id),
      );
    return {
      data: res,
    };
  },

  deleteMany: async () => new Promise(() => {}),
} as DataProvider;
