/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomizedPage_WorkflowElement_Pydantic_ } from '../models/CustomizedPage_WorkflowElement_Pydantic_';
import type { VerdictSchema } from '../models/VerdictSchema';
import type { WorkflowElementStates } from '../models/WorkflowElementStates';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkflowService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List
     * @param orderBy Order by field. Example: `created` or `-created` for descending order
     * @param id
     * @param state
     * @param type
     * @param stage
     * @param startTime
     * @param endTime
     * @param info
     * @param showHistory
     * @param page Page number
     * @param size
     * @param authorization
     * @returns CustomizedPage_WorkflowElement_Pydantic_ Successful Response
     * @throws ApiError
     */
    public list(
        orderBy?: any,
        id?: number,
        state?: WorkflowElementStates,
        type?: string,
        stage?: number,
        startTime?: string,
        endTime?: string,
        info?: string,
        showHistory: boolean = false,
        page: number = 1,
        size: number = 50,
        authorization?: string,
    ): CancelablePromise<CustomizedPage_WorkflowElement_Pydantic_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workflow',
            headers: {
                'authorization': authorization,
            },
            query: {
                'order_by': orderBy,
                'id': id,
                'state': state,
                'type': type,
                'stage': stage,
                'start_time': startTime,
                'end_time': endTime,
                'info': info,
                'show_history': showHistory,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Submit Verdict
     * @param workflowId
     * @param requestBody
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public submitVerdict(
        workflowId: number,
        requestBody: VerdictSchema,
        authorization?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workflow/{workflow_id}',
            path: {
                'workflow_id': workflowId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
