import WatchLaterIcon from '@mui/icons-material/WatchLater';
import GroupPeriodList from './GroupPeriodList';
import GroupPeriodEdit from './GroupPeriodEdit';
import GroupPeriodCreate from './GroupPeriodCreate';

export default {
  icon: WatchLaterIcon,
  list: GroupPeriodList,
  edit: GroupPeriodEdit,
  create: GroupPeriodCreate,
};
