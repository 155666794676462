import { combineDataProviders } from 'react-admin';
import personalRegProvider from './reg-service/personalRegProvider';
import groupRegProvider from './reg-service/groupRegProvider';
import serverRegProvider from './reg-service/serverRegProvider';
import reservedIPAddressProvider from './reg-service/reservedIPAddressProvider';
import periodProvider from './reg-service/periodProvider';
import groupPeriodProvider from './reg-service/groupPeriodProvider';
import priceProvider from './reg-service/priceProvider';
import availableIpProvider from './reg-service/availableIpProvider';
import workflowProvider from './workflow-service/workflowProvider';
import dnsV2RecordProvider from './dns-service/v2RecordProvider';
import dnsV2ZoneProvider from './dns-service/v2ZoneProvider';

const adminschDataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case 'personal_regs':
      return personalRegProvider;
    case 'group_regs':
      return groupRegProvider;
    case 'server_regs':
      return serverRegProvider;
    case 'reserved_ip_addresses':
      return reservedIPAddressProvider;
    case 'periods':
      return periodProvider;
    case 'group_periods':
      return groupPeriodProvider;
    case 'prices':
      return priceProvider;
    case 'available_ips':
      return availableIpProvider;
    case 'workflow':
      return workflowProvider;
    case 'dns_v2_record':
      return dnsV2RecordProvider;
    case 'dns_v2_zone':
      return dnsV2ZoneProvider;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

export default {
  adminschDataProvider,
};
