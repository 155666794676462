import { DnsService } from './dns-service';
import { RegService } from './reg-service/RegService';
import { WorkflowV2Service } from './workflow-v2-service';

export const openAPI = {
  RegService: new RegService({
    BASE: '/api/reg-service/v1',
    TOKEN: async (options) => {
      return localStorage.getItem('jwt') || "";
    },
  }),
  WorkflowV2Service: new WorkflowV2Service({
    BASE: '/api/workflow-v2-service',
    TOKEN: async (options) => {
      return localStorage.getItem('jwt') || "";
    },
  }),
  DnsService: new DnsService({
    BASE: '/api/dns-service',
    TOKEN: async (options) => {
      return localStorage.getItem('jwt') || "";
    },
  })
};

export default openAPI;
