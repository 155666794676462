import {
  Edit,
  Labeled,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
  ListButton,
  CloneButton,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
} from 'react-admin';
import { PersonalRegistration } from '../../../services/openapi/reg-service';
import { Box, Card, CardContent, FormHelperText, Grid } from '@mui/material';

const PersonalRegEdit = () => {
  const translation = useTranslate();
  return (
    <Edit title={<PersonalRegTitle />} component="div" mutationMode="pessimistic">
      <Box maxWidth="50em">
        <Card>
          <ListButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <CloneButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <SimpleForm>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Labeled source="id">
                    <TextField
                      source="id"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ReferenceInput source="ipv4" reference="available_ips">
                    <AutocompleteInput
                      optionText="id"
                      source="ipv4"
                      fullWidth
                      size="small"
                      helperText="resources.personal_regs.fields.ipv4.helperText"
                      translateChoice={false}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="ipv6"
                    fullWidth
                    helperText="resources.personal_regs.fields.ipv6.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <ArrayInput
                    source="macs"
                    isRequired
                    fullWidth
                    helperText="resources.personal_regs.fields.macs.helperText"
                  >
                    <SimpleFormIterator fullWidth inline disableReordering>
                      <TextInput
                        source="mac"
                        helperText={false}
                        sx={{ marginRight: '.4rem', marginTop: '.4rem' }}
                      />
                      <TextInput
                        source="comment"
                        helperText={false}
                        sx={{ marginRight: '.4rem', marginTop: '.4rem' }}
                      />
                      <BooleanInput
                        source="dhcp"
                        helperText={false}
                        defaultValue={true}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
                <Grid item xs={12} md={12}>
                  <BooleanInput
                    source="enabled"
                    isRequired
                    fullWidth
                    defaultValue={true}
                    helperText="resources.personal_regs.fields.enabled.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="nextsrv"
                    fullWidth
                    helperText="resources.personal_regs.fields.nextsrv.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="pxefile"
                    fullWidth
                    helperText="resources.personal_regs.fields.pxefile.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    source="annotation"
                    multiline
                    fullWidth
                    helperText="resources.personal_regs.fields.annotation.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <NumberInput
                    source="period_id"
                    multiline
                    fullWidth
                    disabled
                    helperText="resources.personal_regs.fields.period_id.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="schacc"
                    multiline
                    fullWidth
                    disabled
                    helperText="resources.personal_regs.fields.schacc.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="tier"
                    multiline
                    fullWidth
                    defaultValue="{}"
                    helperText="resources.personal_regs.fields.tier.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="order_id"
                    multiline
                    fullWidth
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="expected_item_id"
                    multiline
                    fullWidth
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="actual_item_id"
                    multiline
                    fullWidth
                    helperText={false}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </SimpleForm>
        </Card>
      </Box>
    </Edit>
  );
};

const PersonalRegTitle = () => {
  const record = useRecordContext<PersonalRegistration>();
  const translation = useTranslate();
  return record ? (
    <span>
      {translation('resources.personal_regs.edit_title')} {record.uuid}
    </span>
  ) : null;
};

export default PersonalRegEdit;
