/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Zone } from '../models/Zone';
import type { ZoneIn } from '../models/ZoneIn';
import type { ZoneUpdate } from '../models/ZoneUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ZonesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Zones
     * @param authorization
     * @returns Zone Successful Response
     * @throws ApiError
     */
    public listZones(
        authorization?: string,
    ): CancelablePromise<Array<Zone>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/zones/',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Zone
     * @param requestBody
     * @param authorization
     * @returns Zone Successful Response
     * @throws ApiError
     */
    public createZone(
        requestBody: ZoneIn,
        authorization?: string,
    ): CancelablePromise<Zone> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/zones/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Zone
     * @param zoneId
     * @param authorization
     * @returns Zone Successful Response
     * @throws ApiError
     */
    public getZone(
        zoneId: number,
        authorization?: string,
    ): CancelablePromise<Zone> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/zones/{zone_id}',
            path: {
                'zone_id': zoneId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Zone
     * @param zoneId
     * @param requestBody
     * @param authorization
     * @returns Zone Successful Response
     * @throws ApiError
     */
    public updateZone(
        zoneId: number,
        requestBody: ZoneUpdate,
        authorization?: string,
    ): CancelablePromise<Zone> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/zones/{zone_id}',
            path: {
                'zone_id': zoneId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Zone
     * @param zoneId
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deleteZone(
        zoneId: number,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/zones/{zone_id}',
            path: {
                'zone_id': zoneId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
