import { DataProvider } from 'ra-core';
import openAPI from '../../services/openapi/openAPI';

export default {
  getList: async (resource, params) => {
    const res = await openAPI.DnsService.v2Zones.listZones(
      `${params.sort.order === 'DESC' ? '-' : ''}${params.sort.field}`,
      params.filter.id,
      params.filter.name,
      params.filter.schOnly,
      params.filter.versionGte,
      params.filter.versionLte,
      params.filter.masterDns,
      params.filter.rname,
      params.filter.refresh,
      params.filter.refreshGte,
      params.filter.refreshLte,
      params.filter.retry,
      params.filter.retryGte,
      params.filter.retryLte,
      params.filter.expire,
      params.filter.expireGte,
      params.filter.expireLte,
      params.filter.ttl,
      params.filter.ttlGte,
      params.filter.ttlLte,
      params.pagination.page,
      params.pagination.perPage,
    );

    const data = res.items.map((r) => ({
      ...r,
    }));

    return {
      data: data,
      total: res.total,
    };
  },

  getOne: async (resource, params) => {
    const res = await openAPI.DnsService.v2Zones.listZones(
      undefined,
      Number(params.id),
    );
    if (res.total === 0) {
      return { data: undefined };
    } else {
      return {
        data: res.items[0],
      };
    }
  },

  getMany: async () =>
    new Promise(() => {
      console.log('getMany');
    }),

  getManyReference: async () =>
    new Promise(() => {
      console.log('getManyReference');
    }),

  update: async (resource, params) => {
    return {
      data: undefined,
    };
  },

  updateMany: async () =>
    new Promise(() => {
      console.log('updateMany');
    }),

  create: async (resource, params) => {
    return {
      data: undefined,
    };
  },

  delete: async (resource, params) => {
    return {
      data: undefined,
    };
  },

  deleteMany: async () =>
    new Promise(() => {
      console.log('deleteMany');
    }),
} as DataProvider;
