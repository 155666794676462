/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Price } from '../models/Price';
import type { PriceUpdate } from '../models/PriceUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PricesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Prices
     * @param periodId
     * @param authorization
     * @returns Price Successful Response
     * @throws ApiError
     */
    public listPricesPricePeriodIdGet(
        periodId: number,
        authorization?: string,
    ): CancelablePromise<Array<Price>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/price/{period_id}',
            path: {
                'period_id': periodId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Price
     * @param periodId
     * @param tier
     * @param authorization
     * @returns Price Successful Response
     * @throws ApiError
     */
    public getPricePricePeriodIdTierGet(
        periodId: number,
        tier: string,
        authorization?: string,
    ): CancelablePromise<Price> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/price/{period_id}/{tier}',
            path: {
                'period_id': periodId,
                'tier': tier,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Price
     * @param periodId
     * @param tier
     * @param requestBody
     * @param authorization
     * @returns Price Successful Response
     * @throws ApiError
     */
    public updatePricePricePeriodIdTierPut(
        periodId: number,
        tier: string,
        requestBody: PriceUpdate,
        authorization?: string,
    ): CancelablePromise<Price> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/price/{period_id}/{tier}',
            path: {
                'period_id': periodId,
                'tier': tier,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Price
     * @param periodId
     * @param tier
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deletePricePricePeriodIdTierDelete(
        periodId: number,
        tier: string,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/price/{period_id}/{tier}',
            path: {
                'period_id': periodId,
                'tier': tier,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Price
     * @param requestBody
     * @param authorization
     * @returns Price Successful Response
     * @throws ApiError
     */
    public createPricePricePost(
        requestBody: Price,
        authorization?: string,
    ): CancelablePromise<Price> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/price/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
