import {
  Edit,
  Labeled,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
  DateTimeInput,
  ListButton,
  CloneButton,
  useTranslate,
  ReferenceManyField,
  NumberField,
  Datagrid,
  useRedirect,
  EditButton,
} from 'react-admin';
import { RegistrationPeriod } from '../../../services/openapi/reg-service';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const PeriodEdit = () => {
  const translation = useTranslate();
  return (
    <Edit title={<PeriodTitle />} component="div" mutationMode="pessimistic">
      <Box maxWidth="50em">
        <Card>
          <ListButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <CloneButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <SimpleForm>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={1}>
                  <Labeled source="Id">
                    <TextField
                      source="id"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={11}>
                  <TextInput
                    source="name"
                    isRequired
                    fullWidth
                    helperText="resources.periods.fields.name.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="start_time"
                    isRequired
                    fullWidth
                    helperText="resources.periods.fields.start_time.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="free_from"
                    isRequired
                    fullWidth
                    helperText="resources.periods.fields.free_from.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="valid_until"
                    fullWidth
                    helperText="resources.periods.fields.valid_until.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimeInput
                    source="deleted_after"
                    fullWidth
                    helperText="resources.periods.fields.deleted_after.helperText"
                  />
                </Grid>
              </Grid>
              <Alert severity="warning" sx={{ marginTop: '1rem' }}>
                {translation('resources.alert.period_deletion_price')}
              </Alert>
            </CardContent>
          </SimpleForm>
        </Card>
        <Card sx={{ marginTop: '1rem' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="1rem"
              >
                <Typography variant="h6">
                  {translation('resources.periods.related_prices')}
                </Typography>
                <PriceCreateRedirectWithPeriod />
              </Grid>
            </Grid>
            <ReferenceManyField
              label="prices"
              reference="prices"
              target="period_id"
            >
              <Datagrid optimized bulkActionButtons={false}>
                <TextField source="tier" />
                <NumberField
                  source="item_id"
                  label="Item_id"
                  options={{
                    useGrouping: false,
                  }}
                />
                <NumberField
                  source="price"
                  options={{
                    useGrouping: false,
                  }}
                />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </CardContent>
        </Card>
      </Box>
    </Edit>
  );
};

const PeriodTitle = () => {
  const record = useRecordContext<RegistrationPeriod>();
  const translation = useTranslate();
  return record ? (
    <span>
      {translation('resources.periods.edit_title')} {record.name}
    </span>
  ) : null;
};

const PriceCreateRedirectWithPeriod = () => {
  const record = useRecordContext<RegistrationPeriod>();
  const redirect = useRedirect();
  const translation = useTranslate();
  return record ? (
    <Button
      variant="outlined"
      onClick={() => {
        redirect(`/prices/create?source={"period_id":${record.id}}`);
      }}
      startIcon={<AddIcon />}
      size="small"
    >
      {translation('resources.prices.create_button')}
    </Button>
  ) : null;
};

export default PeriodEdit;
