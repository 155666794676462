/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AvailableIpsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Available Ips
     * @param subnet
     * @returns string Successful Response
     * @throws ApiError
     */
    public getAvailableIpsAvailableIpsGet(
        subnet: string,
    ): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/available/ips',
            query: {
                'subnet': subnet,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Is Ip Available
     * @param ip
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public isIpAvailableAvailableIpIpGet(
        ip: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/available/ip/{ip}',
            path: {
                'ip': ip,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Is Mac Available
     * @param macAddr
     * @returns any Successful Response
     * @throws ApiError
     */
    public isMacAvailableAvailableMacMacAddrGet(
        macAddr: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/available/mac/{mac_addr}',
            path: {
                'mac_addr': macAddr,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
