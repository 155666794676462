import StorageIcon from '@mui/icons-material/Storage';
import ServerRegList from './ServerRegList';
import ServerRegEdit from './ServerRegEdit';
import ServerRegCreate from './ServerRegCreate';

export default {
  icon: StorageIcon,
  list: ServerRegList,
  edit: ServerRegEdit,
  create: ServerRegCreate,
};
