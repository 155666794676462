import { DataProvider } from 'ra-core';
import openAPI from '../../services/openapi/openAPI';

function checkIfValidIPv4(str: string): boolean {
  // Regular expression to check if string is an IPv4 address
  const regexExp =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

  return regexExp.test(str);
}

export default {
  getList: async (resource, params) => {
    const filterquery = params.filter.q;
    if (
      !filterquery ||
      !filterquery.match(/\./g) ||
      filterquery.match(/\./g).length < 3
    ) {
      return { data: [], total: 0 };
    }
    const subnetParts = params.filter.q.split('.');
    if (
      !checkIfValidIPv4(
        `${subnetParts[0]}.${subnetParts[1]}.${subnetParts[2]}.0`,
      )
    ) {
      return { data: [], total: 0 };
    }
    const res =
      await openAPI.RegService.availableIps.getAvailableIpsAvailableIpsGet(
        `${subnetParts[0]}.${subnetParts[1]}.${subnetParts[2]}.0/24`,
      );
    const filteredRes = res.filter((r) => r.startsWith(filterquery));
    return {
      data: filteredRes.map((r) => ({ id: r })),
      total: filteredRes.length,
    };
  },

  getOne: async (resource, params) => {
    return { data: [] };
  },

  getMany: async (resource, params) => {
    return {
      data: params.ids.map((i) => ({
        id: i,
      })),
      total: 1,
    };
  },

  getManyReference: async () => {
    return { data: [] };
  },

  update: async () => {
    return { data: [] };
  },

  updateMany: async () => {
    return { data: [] };
  },

  create: async () => {
    return { data: [] };
  },

  delete: async () => {
    return { data: [] };
  },

  deleteMany: async () => {
    return { data: [] };
  },
} as DataProvider;
