import { Alert } from '@mui/material';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  List,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';

const serverRegFilters = [<TextInput label="pos.search" source="q" alwaysOn />];

const ServerRegList = () => {
  const translation = useTranslate();
  return (
    <>
      <Alert severity="info" sx={{ marginTop: '1rem' }}>
        {translation('resources.alert.unfinished_list_api')}
      </Alert>
      <List filters={serverRegFilters}>
        <Datagrid
          isRowSelectable={() => false}
          optimized
          bulkActionButtons={false}
        >
          <TextField source="uuid" />
          <TextField source="ipv4" />
          <TextField source="ipv6" />
          <ArrayField source="macs" sortable={false}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="mac" />
              <TextField source="comment" />
              <BooleanField source="dhcp" />
            </Datagrid>
          </ArrayField>
          <BooleanField source="enabled" />
          <TextField source="nextsrv" />
          <TextField source="pxefile" />
          <TextField source="annotation" />
          <DateField source="last_seen" />
          <BooleanField source="warned" />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default ServerRegList;
