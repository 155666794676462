export const recordTypeInputChoices = [
  { id: 'A', name: 'A' },
  { id: 'AAAA', name: 'AAAA' },
  { id: 'CNAME', name: 'CNAME' },
  { id: 'TXT', name: 'TXT' },
  { id: 'SRV', name: 'SRV' },
  { id: 'MX', name: 'MX' },
  { id: 'NS', name: 'NS' },
  { id: 'PTR', name: 'PTR' },
  { id: 'SSHFP', name: 'SSHFP' },
];
