import { Login } from 'react-admin';
import { LoginForm } from './LoginForm';

const LoginPage = () => {
  return (
    // <Login />
    <Login>
      <LoginForm />
    </Login>
  );
};

export default LoginPage;
