/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenericRegistration } from '../models/GenericRegistration';
import type { Registration } from '../models/Registration';
import type { RegistrationOwners } from '../models/RegistrationOwners';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AllRegistrationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Registrations
     * @param subnet
     * @param enabled
     * @param valid
     * @param macaddr
     * @param limit
     * @param offset
     * @param authorization
     * @returns Registration Successful Response
     * @throws ApiError
     */
    public getAllRegistrationsRegistrationsGet(
        subnet?: string,
        enabled?: boolean,
        valid?: boolean,
        macaddr?: string,
        limit: number = 50,
        offset?: number,
        authorization?: string,
    ): CancelablePromise<Array<Registration>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/registrations/',
            headers: {
                'authorization': authorization,
            },
            query: {
                'subnet': subnet,
                'enabled': enabled,
                'valid': valid,
                'macaddr': macaddr,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Single Registration
     * @param uuid
     * @param authorization
     * @returns GenericRegistration Successful Response
     * @throws ApiError
     */
    public getSingleRegistrationRegistrationsUuidGet(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<GenericRegistration> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Registration Details
     * @param uuid
     * @param authorization
     * @returns RegistrationOwners Successful Response
     * @throws ApiError
     */
    public getRegistrationDetailsRegistrationsUuidOwnersGet(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<RegistrationOwners> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/registrations/{uuid}/owners',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
