/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { models_Record_nmspgp_list } from '../models/models_Record_nmspgp_list';
import type { RecordInSchema } from '../models/RecordInSchema';
import type { RecordSchema } from '../models/RecordSchema';
import type { RecordTXTSchema } from '../models/RecordTXTSchema';
import type { RecordWithZone } from '../models/RecordWithZone';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RecordsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Delete Acme Record
     * @param requestBody
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deleteAcmeRecord(
        requestBody: RecordTXTSchema,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/records/delete-acme-record',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Acme Record
     * @param requestBody
     * @param authorization
     * @returns RecordSchema Successful Response
     * @throws ApiError
     */
    public createAcmeRecord(
        requestBody: RecordTXTSchema,
        authorization?: string,
    ): CancelablePromise<RecordSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/records/acme-record',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Records For Registration
     * @param regUuid
     * @param authorization
     * @returns models_Record_nmspgp_list Successful Response
     * @throws ApiError
     */
    public getRecordsForRegistration(
        regUuid: string,
        authorization?: string,
    ): CancelablePromise<models_Record_nmspgp_list> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/records/registration/{reg_uuid}',
            path: {
                'reg_uuid': regUuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Primary Hostname For Multiple Reg
     * @param requestBody
     * @param authorization
     * @returns RecordWithZone Successful Response
     * @throws ApiError
     */
    public getPrimaryHostnameForMultipleReg(
        requestBody: Array<string>,
        authorization?: string,
    ): CancelablePromise<Array<RecordWithZone>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/records/primary-hostname-for-multiple-reg',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Record By Id
     * @param recordId
     * @param authorization
     * @returns RecordWithZone Successful Response
     * @throws ApiError
     */
    public getRecordById(
        recordId: number,
        authorization?: string,
    ): CancelablePromise<RecordWithZone> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/records/{record_id}',
            path: {
                'record_id': recordId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Record
     * @param recordId
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deleteRecord(
        recordId: number,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/records/{record_id}',
            path: {
                'record_id': recordId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Records In Zone
     * @param zoneId
     * @param authorization
     * @returns RecordSchema Successful Response
     * @throws ApiError
     */
    public getRecordsInZone(
        zoneId: number,
        authorization?: string,
    ): CancelablePromise<Array<RecordSchema>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/records/zone/{zone_id}',
            path: {
                'zone_id': zoneId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Record
     * @param requestBody
     * @param authorization
     * @returns RecordSchema Successful Response
     * @throws ApiError
     */
    public createRecord(
        requestBody: RecordInSchema,
        authorization?: string,
    ): CancelablePromise<RecordSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/records/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Primary Hostname For Reg
     * @param regUuid
     * @param authorization
     * @returns RecordWithZone Successful Response
     * @throws ApiError
     */
    public getPrimaryHostnameForReg(
        regUuid: string,
        authorization?: string,
    ): CancelablePromise<RecordWithZone> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/records/primary-hostname-for-reg/{reg_uuid}',
            path: {
                'reg_uuid': regUuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Primary Hostname
     * @param recordId
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public setPrimaryHostname(
        recordId: number,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/records/set-primary-hostname/{record_id}',
            path: {
                'record_id': recordId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
