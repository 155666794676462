/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReservedIP } from '../models/ReservedIP';
import type { ReservedIPEdit } from '../models/ReservedIPEdit';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReservedIpAddressesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Reserved Ips
     * @param authorization
     * @returns ReservedIP Successful Response
     * @throws ApiError
     */
    public getReservedIpsReservedGet(
        authorization?: string,
    ): CancelablePromise<Array<ReservedIP>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/reserved/',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Reserved Ip
     * @param requestBody
     * @param authorization
     * @returns ReservedIP Successful Response
     * @throws ApiError
     */
    public createReservedIpReservedPost(
        requestBody: ReservedIPEdit,
        authorization?: string,
    ): CancelablePromise<ReservedIP> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/reserved/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Reserved Ip
     * @param id
     * @param authorization
     * @returns ReservedIP Successful Response
     * @throws ApiError
     */
    public getReservedIpReservedIdGet(
        id: number,
        authorization?: string,
    ): CancelablePromise<ReservedIP> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/reserved/{id}',
            path: {
                'id': id,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Reserved Ip
     * @param id
     * @param requestBody
     * @param authorization
     * @returns ReservedIP Successful Response
     * @throws ApiError
     */
    public updateReservedIpReservedIdPut(
        id: number,
        requestBody: ReservedIPEdit,
        authorization?: string,
    ): CancelablePromise<ReservedIP> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/reserved/{id}',
            path: {
                'id': id,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Reserved Ip
     * @param id
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deleteReservedIpReservedIdDelete(
        id: number,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/reserved/{id}',
            path: {
                'id': id,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
