import {
  Labeled,
  SimpleForm,
  TextField,
  TextInput,
  Create,
  useTranslate,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  AutocompleteInput,
  ReferenceInput,
  NumberInput,
} from 'react-admin';
import { Box, Card, CardContent, FormHelperText, Grid } from '@mui/material';

const PersonalRegCreate = () => {
  const translation = useTranslate();
  return (
    <Create
      title={<span>{translation('resources.personal_regs.create_title')}</span>}
      component="div"
    >
      <Box maxWidth="50em">
        <Card>
          <ListButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <SimpleForm>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Labeled source="id">
                    <TextField
                      source="id"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                      emptyText="Auto generated"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ReferenceInput source="ipv4" reference="available_ips">
                    <AutocompleteInput
                      optionText="id"
                      source="ipv4"
                      fullWidth
                      size="small"
                      helperText="resources.personal_regs.fields.ipv4.helperText"
                      translateChoice={false}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Labeled source="ipv6_">
                    <TextField
                      source="ipv6_"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                      emptyText="Auto generated"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={12}>
                  <ArrayInput
                    source="macs"
                    isRequired
                    fullWidth
                    helperText="resources.personal_regs.fields.macs.helperText"
                  >
                    <SimpleFormIterator fullWidth inline disableReordering>
                      <TextInput
                        source="mac"
                        helperText={false}
                        sx={{ marginRight: '.4rem', marginTop: '.4rem' }}
                      />
                      <TextInput
                        source="comment"
                        helperText={false}
                        sx={{ marginRight: '.4rem', marginTop: '.4rem' }}
                      />
                      <BooleanInput
                        source="dhcp"
                        helperText={false}
                        defaultValue={true}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
                <Grid item xs={12} md={12}>
                  <BooleanInput
                    source="enabled"
                    isRequired
                    fullWidth
                    defaultValue={true}
                    helperText="resources.personal_regs.fields.enabled.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="nextsrv"
                    fullWidth
                    helperText="resources.personal_regs.fields.nextsrv.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="pxefile"
                    fullWidth
                    helperText="resources.personal_regs.fields.pxefile.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    source="annotation"
                    multiline
                    fullWidth
                    defaultValue="{}"
                    helperText="resources.personal_regs.fields.annotation.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="schacc"
                    multiline
                    fullWidth
                    helperText="resources.personal_regs.fields.schacc.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source="tier"
                    multiline
                    fullWidth
                    helperText="resources.personal_regs.fields.tier.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="order_id"
                    multiline
                    fullWidth
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="expected_item_id"
                    multiline
                    fullWidth
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="actual_item_id"
                    multiline
                    fullWidth
                    helperText={false}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </SimpleForm>
        </Card>
      </Box>
    </Create>
  );
};

export default PersonalRegCreate;
