export function formatDateWithoutTimezone(date: Date) {
  return date.toISOString().slice(0, 19);
}

// TODO: Make API able to process dates with timezone
export function convertDatesToStringsWithoutTimezone(obj: any) {
  console.log(obj);
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value instanceof Date) {
        obj[key] = formatDateWithoutTimezone(value);
      } else if (typeof value === 'object') {
        convertDatesToStringsWithoutTimezone(value); // Recursively process nested objects
      }
    }
  }
  return obj;
}
