/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OwnRegistrationCreate } from '../models/OwnRegistrationCreate';
import type { OwnRegistrationRenew } from '../models/OwnRegistrationRenew';
import type { OwnRegistrationUpdate } from '../models/OwnRegistrationUpdate';
import type { PersonalRegistration } from '../models/PersonalRegistration';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MyRegistrationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Remaining
     * @param ip
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public remainingMyRegistrationsRemainingGet(
        ip?: string,
        authorization?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/my-registrations/remaining',
            headers: {
                'authorization': authorization,
            },
            query: {
                'ip': ip,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List
     * @param authorization
     * @returns PersonalRegistration Successful Response
     * @throws ApiError
     */
    public listMyRegistrationsGet(
        authorization?: string,
    ): CancelablePromise<Array<PersonalRegistration>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/my-registrations/',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create
     * @param requestBody
     * @param authorization
     * @returns PersonalRegistration Successful Response
     * @throws ApiError
     */
    public createMyRegistrationsPost(
        requestBody: OwnRegistrationCreate,
        authorization?: string,
    ): CancelablePromise<PersonalRegistration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/my-registrations/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get
     * @param uuid
     * @param authorization
     * @returns PersonalRegistration Successful Response
     * @throws ApiError
     */
    public getMyRegistrationsUuidGet(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<PersonalRegistration> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/my-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update
     * @param uuid
     * @param requestBody
     * @param authorization
     * @returns PersonalRegistration Successful Response
     * @throws ApiError
     */
    public updateMyRegistrationsUuidPut(
        uuid: string,
        requestBody: OwnRegistrationUpdate,
        authorization?: string,
    ): CancelablePromise<PersonalRegistration> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/my-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param uuid
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deleteMyRegistrationsUuidDelete(
        uuid: string,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/my-registrations/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Renew
     * @param uuid
     * @param requestBody
     * @param authorization
     * @returns PersonalRegistration Successful Response
     * @throws ApiError
     */
    public renewMyRegistrationsUuidRenewPost(
        uuid: string,
        requestBody: OwnRegistrationRenew,
        authorization?: string,
    ): CancelablePromise<PersonalRegistration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/my-registrations/{uuid}/renew',
            path: {
                'uuid': uuid,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
