import { TranslationMessages } from 'react-admin';

const hungarianMessages: TranslationMessages = {
  //   TODO: Review, translated using ChatGPT
  ra: {
    action: {
      add_filter: 'Szűrő hozzáadása',
      add: 'Hozzáadás',
      back: 'Vissza',
      bulk_actions: '1 elem kiválasztva |||| %{smart_count} elem kiválasztva',
      cancel: 'Mégse',
      clear_array_input: 'Lista törlése',
      clear_input_value: 'Érték törlése',
      clone: 'Klónozás',
      confirm: 'Megerősítés',
      create: 'Létrehozás',
      create_item: '%{item} létrehozása',
      delete: 'Törlés',
      edit: 'Szerkesztés',
      export: 'Exportálás',
      list: 'Lista',
      refresh: 'Frissítés',
      remove_filter: 'Szűrő eltávolítása',
      remove_all_filters: 'Minden szűrő eltávolítása',
      remove: 'Eltávolítás',
      save: 'Mentés',
      search: 'Keresés',
      select_all: 'Minden kiválasztása',
      select_row: 'Sor kiválasztása',
      show: 'Mutatás',
      sort: 'Rendezés',
      undo: 'Visszavonás',
      unselect: 'Kiválasztás megszüntetése',
      expand: 'Kibontás',
      close: 'Bezárás',
      open_menu: 'Menü megnyitása',
      close_menu: 'Menü bezárása',
      update: 'Frissítés',
      move_up: 'Feljebb mozgatás',
      move_down: 'Lejjebb mozgatás',
      open: 'Megnyitás',
      toggle_theme: 'Téma váltása',
      select_columns: 'Oszlopok',
      update_application: 'Alkalmazás újratöltése',
    },
    boolean: {
      true: 'Igen',
      false: 'Nem',
      null: ' ', // It seems like a space character is provided here, which may not need translation.
    },
    page: {
      create: '%{name} létrehozása',
      dashboard: 'Vezérlőpult',
      edit: '%{name} %{recordRepresentation} szerkesztése',
      error: 'Valami hiba történt',
      list: '%{name}',
      loading: 'Betöltés',
      not_found: 'Nem található',
      show: '%{name} %{recordRepresentation} megjelenítése',
      empty: 'Még nincsenek %{name}.',
      invite: 'Szeretnél hozzáadni egyet?',
    },
    input: {
      file: {
        upload_several:
          'Húzz ide néhány fájlt a feltöltéshez, vagy kattints a kiválasztáshoz.',
        upload_single:
          'Húzz ide egy fájlt a feltöltéshez, vagy kattints a kiválasztáshoz.',
      },
      image: {
        upload_several:
          'Húzz ide néhány képet a feltöltéshez, vagy kattints a kiválasztáshoz.',
        upload_single:
          'Húzz ide egy képet a feltöltéshez, vagy kattints a kiválasztáshoz.',
      },
      references: {
        all_missing: 'Nem sikerült megtalálni a hivatkozások adatait.',
        many_missing:
          'Legalább az egyik kapcsolódó hivatkozás már nem elérhető.',
        single_missing: 'A kapcsolódó hivatkozás már nem elérhető.',
      },
      password: {
        toggle_visible: 'Jelszó elrejtése',
        toggle_hidden: 'Jelszó mutatása',
      },
    },
    message: {
      about: 'Névjegy',
      are_you_sure: 'Biztos vagy benne?',
      auth_error: 'Hiba történt az azonosító token érvényesítése közben.',
      bulk_delete_content:
        'Biztosan törölni szeretnéd ezt a(z) %{name}-t? |||| Biztosan törölni szeretnéd ezeket a(z) %{smart_count} elemet?',
      bulk_delete_title: 'Törlés: %{name} |||| Törlés: %{smart_count} %{name}',
      bulk_update_content:
        'Biztosan frissíteni szeretnéd ezt a(z) %{name}-t? |||| Biztosan frissíteni szeretnéd ezeket a(z) %{smart_count} elemet?',
      bulk_update_title:
        'Frissítés: %{name} |||| Frissítés: %{smart_count} %{name}',
      clear_array_input: 'Biztosan törölni szeretnéd az egész listát?',
      delete_content: 'Biztosan törölni szeretnéd ezt az elemet?',
      delete_title: 'Törlés: %{name} #%{id}',
      details: 'Részletek',
      error: 'Ügyfél hiba történt, és a kérésed nem fejeződött be.',

      invalid_form: 'Az űrlap érvénytelen. Kérlek, ellenőrizd a hibákat.',
      loading: 'Az oldal betöltődik, csak egy pillanat türelmet',
      no: 'Nem',
      not_found: 'Vagy rossz URL-t írtál be, vagy egy rossz linket követtél.',
      yes: 'Igen',
      unsaved_changes:
        'Néhány változtatásod nincsenek mentve. Biztosan figyelmen kívül szeretnéd hagyni őket?',
    },
    navigation: {
      no_results: 'Nincs találat',
      no_more_results:
        'Az oldalszám %{page} kívül esik a határokon. Próbáld meg az előző oldalt.',
      page_out_of_boundaries: 'Az oldalszám %{page} kívül esik a határokon',
      page_out_from_end: 'Nem mehetsz a utolsó oldal után',
      page_out_from_begin: 'Nem mehetsz az 1. oldal elé',
      page_range_info: '%{offsetBegin}-%{offsetEnd} / %{total}',
      partial_page_range_info:
        '%{offsetBegin}-%{offsetEnd} / több, mint %{offsetEnd}',
      current_page: 'Oldal %{page}',
      page: 'Ugrás a(z) %{page}. oldalra',
      first: 'Ugrás az első oldalra',
      last: 'Ugrás az utolsó oldalra',
      next: 'Ugrás a következő oldalra',
      previous: 'Ugrás az előző oldalra',
      page_rows_per_page: 'Sorok oldalanként:',
      skip_nav: 'Ugrás a tartalomhoz',
    },
    sort: {
      sort_by: '%{field} %{order} szerinti rendezés',
      ASC: 'növekvő sorrendben',
      DESC: 'csökkenő sorrendben',
    },
    auth: {
      auth_check_error: 'Kérlek, jelentkezz be a folytatáshoz',
      user_menu: 'Profil',
      username: 'Felhasználónév',
      password: 'Jelszó',
      sign_in: 'Bejelentkezés',
      sign_in_error: 'Az azonosítás sikertelen, kérlek, próbáld újra',
      logout: 'Kijelentkezés',
    },
    notification: {
      updated: 'Elem frissítve |||| %{smart_count} elem frissítve',
      created: 'Elem létrehozva',
      deleted: 'Elem törölve |||| %{smart_count} elem törölve',
      bad_item: 'Helytelen elem',
      item_doesnt_exist: 'Az elem nem létezik',
      http_error: 'Szerver kommunikációs hiba',
      data_provider_error:
        'dataProvider hiba. Ellenőrizd a konzolt a részletekért.',
      i18n_error: 'Nem lehet betölteni a meghatározott nyelv fordításait',
      canceled: 'Művelet megszakítva',
      logged_out: 'A munkamenet lejárt, kérlek, lépj újra be.',
      not_authorized: 'Nincs jogosultságod hozzáférni ehhez az erőforráshoz.',
      application_update_available: 'Új verzió érhető el.',
    },
    validation: {
      required: 'Kötelező mező',
      minLength: 'Legalább %{min} karakter hosszú kell legyen',
      maxLength: 'Legfeljebb %{max} karakter hosszú lehet',
      minValue: 'Legalább %{min} értékű kell legyen',
      maxValue: 'Legfeljebb %{max} értékű lehet',
      number: 'Szám kell legyen',
      email: 'Valós e-mail cím kell legyen',
      oneOf: 'Egyike kell legyen a következőknek: %{options}',
      regex: 'Meg kell feleljen a következő formátumnak (regexp): %{pattern}',
      unique: 'Egyedinek kell lennie',
    },
    saved_queries: {
      label: 'Mentett lekérdezések',
      query_name: 'Lekérdezés neve',
      new_label: 'Aktuális lekérdezés mentése...',
      new_dialog_title: 'Aktuális lekérdezés mentése mint',
      remove_label: 'Mentett lekérdezés eltávolítása',
      remove_label_with_name: 'Lekérdezés eltávolítása: "%{name}"',
      remove_dialog_title: 'Mentett lekérdezés eltávolítása?',
      remove_message:
        'Biztosan el szeretnéd távolítani ezt az elemet a mentett lekérdezéseid listájáról?',
      help: 'Szűrd a listát és mentsd el ezt a lekérdezést későbbi használatra',
    },
    configurable: {
      customize: 'Testreszabás',
      configureMode: 'Oldal testreszabása',
      inspector: {
        title: 'Vizsgáló',
        content:
          'Az alkalmazás UI elemeinek konfigurálásához tartsd föléjük az egeret',
        reset: 'Beállítások alaphelyzetbe állítása',
        hideAll: 'Összes elrejtése',
        showAll: 'Összes megjelenítése',
      },
      Datagrid: {
        title: 'Adattáblázat',
        unlabeled: 'Címke nélküli oszlop #%{column}',
      },
      SimpleForm: {
        title: 'Űrlap',
        unlabeled: 'Címke nélküli mező #%{input}',
      },
      SimpleList: {
        title: 'Lista',
        primaryText: 'Elsődleges szöveg',
        secondaryText: 'Másodlagos szöveg',
        tertiaryText: 'Harmadlagos szöveg',
      },
    },
  },
};

export default hungarianMessages;
