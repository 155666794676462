import { Admin, Resource } from 'react-admin';
import { QueryClient } from 'react-query';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { BaseLayout, Menu } from './layout';
import hungarianMessages from './i18n/hu';
import { lightTheme, darkTheme } from './layout/themes';

import personalRegs from './resources/reg-service/personalRegs';
import groupRegs from './resources/reg-service/groupRegs';
import serverRegs from './resources/reg-service/serverRegs';
import reservedIpAddresses from './resources/reg-service/reservedIpAddresses';
import periods from './resources/reg-service/periods';
import groupPeriods from './resources/reg-service/groupPeriods';
import dataProvider from './dataProvider';
import prices from './resources/reg-service/prices';
import workflow from './resources/workflow-service';
import dnsRecords from './resources/dns-service/records';
import dnsZones from './resources/dns-service/zones';
import authProvider from './authProvider';
import LoginPage from './LoginPage';
// import authProvider from './authProvider';
// import LoginPage from './LoginPage';

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    // Always fallback on hungarian
    return hungarianMessages;
  },
  'hu',
  [
    { locale: 'en', name: 'English' },
    { locale: 'hu', name: 'Magyar' },
  ],
);

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1 * 60 * 1000, // 1 minutes
      },
    },
  });
  return (
    <Admin
      title="AdminSCH"
      dataProvider={dataProvider.adminschDataProvider}
      queryClient={queryClient}
      layout={BaseLayout}
      i18nProvider={i18nProvider}
      disableTelemetry
      theme={lightTheme}
      darkTheme={darkTheme}
      defaultTheme="dark"
      authProvider={authProvider}
      loginPage={LoginPage}
    >
      {(permissions: string[]) =>
        permissions.includes('b2b') ? (
          <>
            <Resource name="personal_regs" {...personalRegs} />
            <Resource name="group_regs" {...groupRegs} />
            <Resource name="server_regs" {...serverRegs} />
            <Resource name="reserved_ip_addresses" {...reservedIpAddresses} />
            <Resource name="periods" {...periods} />
            <Resource name="group_periods" {...groupPeriods} />
            <Resource name="prices" {...prices} />
            <Resource name="workflow" {...workflow} />
            <Resource name="dns_v2_record" {...dnsRecords} />
            <Resource name="dns_v2_zone" {...dnsZones} />
          </>
        ) : null
      }
    </Admin>
  );
};

export default App;
