/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class V2ZoneFileService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Zone File
     * @param zoneId
     * @param showGeneratedPtrRecords
     * @param authorization
     * @returns string Successful Response
     * @throws ApiError
     */
    public getZoneFile(
        zoneId: number,
        showGeneratedPtrRecords: boolean = true,
        authorization?: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/zone_file/{zone_id}',
            path: {
                'zone_id': zoneId,
            },
            headers: {
                'authorization': authorization,
            },
            query: {
                'show_generated_ptr_records': showGeneratedPtrRecords,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
