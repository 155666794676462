/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AllRegistrationsService } from './services/AllRegistrationsService';
import { AvailableIpsService } from './services/AvailableIpsService';
import { GroupRegistrationPeriodsService } from './services/GroupRegistrationPeriodsService';
import { GroupRegistrationsService } from './services/GroupRegistrationsService';
import { MyRegistrationsService } from './services/MyRegistrationsService';
import { PersonalRegistrationsService } from './services/PersonalRegistrationsService';
import { PricesService } from './services/PricesService';
import { RegistrationPeriodsService } from './services/RegistrationPeriodsService';
import { ReservedIpAddressesService } from './services/ReservedIpAddressesService';
import { ServerRegistrationsService } from './services/ServerRegistrationsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class RegService {

    public readonly allRegistrations: AllRegistrationsService;
    public readonly availableIps: AvailableIpsService;
    public readonly groupRegistrationPeriods: GroupRegistrationPeriodsService;
    public readonly groupRegistrations: GroupRegistrationsService;
    public readonly myRegistrations: MyRegistrationsService;
    public readonly personalRegistrations: PersonalRegistrationsService;
    public readonly prices: PricesService;
    public readonly registrationPeriods: RegistrationPeriodsService;
    public readonly reservedIpAddresses: ReservedIpAddressesService;
    public readonly serverRegistrations: ServerRegistrationsService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '/v1',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.allRegistrations = new AllRegistrationsService(this.request);
        this.availableIps = new AvailableIpsService(this.request);
        this.groupRegistrationPeriods = new GroupRegistrationPeriodsService(this.request);
        this.groupRegistrations = new GroupRegistrationsService(this.request);
        this.myRegistrations = new MyRegistrationsService(this.request);
        this.personalRegistrations = new PersonalRegistrationsService(this.request);
        this.prices = new PricesService(this.request);
        this.registrationPeriods = new RegistrationPeriodsService(this.request);
        this.reservedIpAddresses = new ReservedIpAddressesService(this.request);
        this.serverRegistrations = new ServerRegistrationsService(this.request);
    }
}

