/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegistrationPeriod } from '../models/RegistrationPeriod';
import type { RegistrationPeriodCreate } from '../models/RegistrationPeriodCreate';
import type { RegistrationPeriodUpdate } from '../models/RegistrationPeriodUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RegistrationPeriodsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Periods
     * @param authorization
     * @returns RegistrationPeriod Successful Response
     * @throws ApiError
     */
    public listPeriodsPeriodsGet(
        authorization?: string,
    ): CancelablePromise<Array<RegistrationPeriod>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/periods/',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Period
     * @param requestBody
     * @param authorization
     * @returns RegistrationPeriod Successful Response
     * @throws ApiError
     */
    public createPeriodPeriodsPost(
        requestBody: RegistrationPeriodCreate,
        authorization?: string,
    ): CancelablePromise<RegistrationPeriod> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/periods/',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Current Period
     * @param authorization
     * @returns RegistrationPeriod Successful Response
     * @throws ApiError
     */
    public currentPeriodPeriodsCurrentGet(
        authorization?: string,
    ): CancelablePromise<RegistrationPeriod> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/periods/current',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Period
     * @param periodId
     * @param authorization
     * @returns any Successful Response
     * @throws ApiError
     */
    public getPeriodPeriodsPeriodIdGet(
        periodId: number,
        authorization?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/periods/{period_id}',
            path: {
                'period_id': periodId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Period
     * @param periodId
     * @param requestBody
     * @param authorization
     * @returns RegistrationPeriod Successful Response
     * @throws ApiError
     */
    public updatePeriodPeriodsPeriodIdPut(
        periodId: number,
        requestBody: RegistrationPeriodUpdate,
        authorization?: string,
    ): CancelablePromise<RegistrationPeriod> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/periods/{period_id}',
            path: {
                'period_id': periodId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Period
     * @param periodId
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deletePeriodPeriodsPeriodIdDelete(
        periodId: number,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/periods/{period_id}',
            path: {
                'period_id': periodId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
