import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DnsList from './DnsList';
import DnsCreate from './DnsCreate';
import DnsEdit from './DnsEdit';

export default {
  icon: DynamicFeedIcon,
  list: DnsList,
  create: DnsCreate,
  edit: DnsEdit,
};
