import PersonIcon from '@mui/icons-material/Person';
import PersonalRegList from './PersonalRegList';
import PersonalRegEdit from './PersonalRegEdit';
import PersonalRegCreate from './PersonalRegCreate';

export default {
  icon: PersonIcon,
  list: PersonalRegList,
  edit: PersonalRegEdit,
  create: PersonalRegCreate,
};
