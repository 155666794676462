import CancelIcon from '@mui/icons-material/Cancel';
import ReservedIpAddressList from './ReservedIpAddressList';
import ReservedIpAddressCreate from './ReservedIpAddressCreate';
import ReservedIpAddressEdit from './ReservedIpAddressEdit';

export default {
  icon: CancelIcon,
  list: ReservedIpAddressList,
  create: ReservedIpAddressCreate,
  edit: ReservedIpAddressEdit,
};
