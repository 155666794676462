import { DataProvider } from 'ra-core';
import openAPI from '../../services/openapi/openAPI';

export default {
  getList: async (resource, params) => {
    const res = await openAPI.DnsService.v2Records.listRecords(
      `${params.sort.order === 'DESC' ? '-' : ''}${params.sort.field}`,
      params.filter.id,
      params.filter.regUuid,
      params.filter.name,
      params.filter.nameIcontains,
      params.filter.zone,
      params.filter.approved,
      params.filter.primaryHostname,
      params.filter.type,
      params.filter.ttl,
      params.filter.ttlGte,
      params.filter.ttlLte,
      params.filter.value,
      params.filter.valueIcontains,
      params.filter.regUuidIsnull,
      params.pagination.page,
      params.pagination.perPage,
    );

    const data = res.items.map((r) => ({
      ...r,
    }));

    return {
      data: data,
      total: res.total,
    };
  },

  getOne: async (resource, params) => {
    const res = await openAPI.DnsService.v2Records.listRecords(
      undefined,
      Number(params.id),
    );
    if (res.total === 0) {
      return { data: undefined };
    } else {
      return {
        data: res.items[0],
      };
    }
  },

  getMany: async () =>
    new Promise(() => {
      console.log('getMany');
    }),

  getManyReference: async () =>
    new Promise(() => {
      console.log('getManyReference');
    }),

  update: async (resource, params) => {
    const res = await openAPI.DnsService.v2Records.updateRecord(
      Number(params.id),
      {
        ...params.data,
      },
    );

    return {
      data: res,
    };
  },

  updateMany: async () =>
    new Promise(() => {
      console.log('updateMany');
    }),

  create: async (resource, params) => {
    const res = await openAPI.DnsService.v2Records.createRecord({
      name: params.data.name,
      primary_hostname: params.data.primary_hostname,
      type: params.data.type,
      value: params.data.value,
      zone_id: params.data.zone_id,
      approved: params.data.approved,
      reg_uuid: params.data.reg_uuid,
      ttl: params.data.ttl,
      description: params.data.description
    });
    return {
      data: res,
    };
  },

  delete: async (resource, params) => {
    const res = await openAPI.DnsService.v2Records.deleteRecord(
      Number(params.id),
    );

    return {
      data: res,
    };
  },

  deleteMany: async () =>
    new Promise(() => {
      console.log('deleteMany');
    }),
} as DataProvider;
