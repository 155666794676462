import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import LabelIcon from '@mui/icons-material/Label';
import DevicesIcon from '@mui/icons-material/Devices';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
  useNotify,
} from 'react-admin';

import SubMenu from './SubMenu';
import personalRegs from '../resources/reg-service/personalRegs';
import groupRegs from '../resources/reg-service/groupRegs';
import serverRegs from '../resources/reg-service/serverRegs';
import Periods from '../resources/reg-service/periods';
import GroupPeriods from '../resources/reg-service/groupPeriods';
import Workflow from '../resources/workflow-service';
import DnsRecords from '../resources/dns-service/records';
import DnsZones from '../resources/dns-service/zones';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, IconButton } from '@mui/material';
import ReservedIpAddresses from '../resources/reg-service/reservedIpAddresses';

type MenuName = 'menuReg' | 'menuPeriodAndPrice' | 'menuDns';

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuReg: false,
    menuPeriodAndPrice: false,
    menuDns: false,
  });
  const translate = useTranslate();
  const notify = useNotify();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const { permissions }: { permissions: string[] } = usePermissions();

  return (
    <Box
      sx={{
        width: open ? 250 : 50,
        maxWidth: '100%',
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {permissions.includes('b2b') ? (
        <>
          <SubMenu
            handleToggle={() => handleToggle('menuReg')}
            isOpen={state.menuReg}
            name="pos.menu.reg"
            icon={<DevicesIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/personal_regs"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.personal_regs.name`, {
                smart_count: 2,
              })}
              leftIcon={<personalRegs.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/group_regs"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.group_regs.name`, {
                smart_count: 2,
              })}
              leftIcon={<groupRegs.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/server_regs"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.server_regs.name`, {
                smart_count: 2,
              })}
              leftIcon={<serverRegs.icon />}
              dense={dense}
            />

            <MenuItemLink
              to="/reserved_ip_addresses"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.reserved_ip_addresses.name`, {
                smart_count: 2,
              })}
              leftIcon={<ReservedIpAddresses.icon />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle('menuPeriodAndPrice')}
            isOpen={state.menuPeriodAndPrice}
            name="pos.menu.period_and_price"
            icon={<CalendarMonthIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/periods"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.periods.name`, {
                smart_count: 2,
              })}
              leftIcon={<Periods.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/group_periods"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.group_periods.name`, {
                smart_count: 2,
              })}
              leftIcon={<GroupPeriods.icon />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle('menuDns')}
            isOpen={state.menuDns}
            name="pos.menu.dns"
            icon={<LanguageIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/dns_v2_record"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.dns_record.name`, {
                smart_count: 2,
              })}
              leftIcon={<DnsRecords.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/dns_v2_zone"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.dns_zone.name`, {
                smart_count: 2,
              })}
              leftIcon={<DnsZones.icon />}
              dense={dense}
            />
          </SubMenu>
          <MenuItemLink
            to="/workflow"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.workflow.name`, {
              smart_count: 2,
            })}
            leftIcon={<Workflow.icon />}
            dense={dense}
          />
        </>
      ) : null}
      {permissions.includes('dev') ? (
        open ? (
          <Button
            variant="outlined"
            type="submit"
            size="small"
            id="jwtCopyButton"
            sx={{ marginLeft: '1rem', marginTop: '2rem' }}
            startIcon={<ContentCopyIcon fontSize="medium" />}
            onClick={() => {
              navigator.clipboard.writeText(localStorage.getItem('jwt') || '');
              notify('utils.copied_jwt', { type: 'success' });
            }}
            color="success"
          >
            <>{translate('utils.copy_button_text')}</>
          </Button>
        ) : (
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(localStorage.getItem('jwt') || '');
              notify('utils.copied_jwt', { type: 'success' });
            }}
            sx={{ marginLeft: '.5rem', marginTop: '2rem' }}
            color="success"
          >
            <ContentCopyIcon />
          </IconButton>
        )
      ) : null}
    </Box>
  );
};

export default Menu;
