/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { RecordsService } from './services/RecordsService';
import { V2RecordsService } from './services/V2RecordsService';
import { V2ZoneFileService } from './services/V2ZoneFileService';
import { V2ZonesService } from './services/V2ZonesService';
import { ZonesService } from './services/ZonesService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class DnsService {

    public readonly records: RecordsService;
    public readonly v2Records: V2RecordsService;
    public readonly v2ZoneFile: V2ZoneFileService;
    public readonly v2Zones: V2ZonesService;
    public readonly zones: ZonesService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '3.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.records = new RecordsService(this.request);
        this.v2Records = new V2RecordsService(this.request);
        this.v2ZoneFile = new V2ZoneFileService(this.request);
        this.v2Zones = new V2ZonesService(this.request);
        this.zones = new ZonesService(this.request);
    }
}

