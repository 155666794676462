/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageCustomized_Record_Pydantic_ } from '../models/PageCustomized_Record_Pydantic_';
import type { Record_Pydantic } from '../models/Record_Pydantic';
import type { RecordCreateSchema } from '../models/RecordCreateSchema';
import type { RecordUpdateSchema } from '../models/RecordUpdateSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class V2RecordsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Records
     * Queries the currently configured records. Doesn't show automatically generated PTR records!
     * @param orderBy Order by field. Example: `name` or `-name` for descending order
     * @param id
     * @param regUuid
     * @param name
     * @param nameIcontains
     * @param zone
     * @param approved
     * @param primaryHostname
     * @param type
     * @param ttl
     * @param ttlGte
     * @param ttlLte
     * @param value
     * @param valueIcontains
     * @param regUuidIsnull
     * @param page
     * @param size
     * @param authorization
     * @returns PageCustomized_Record_Pydantic_ Successful Response
     * @throws ApiError
     */
    public listRecords(
        orderBy: string = 'id',
        id?: number,
        regUuid?: string,
        name?: string,
        nameIcontains?: string,
        zone?: number,
        approved?: boolean,
        primaryHostname?: boolean,
        type?: string,
        ttl?: number,
        ttlGte?: number,
        ttlLte?: number,
        value?: string,
        valueIcontains?: string,
        regUuidIsnull?: boolean,
        page: number = 1,
        size: number = 50,
        authorization?: string,
    ): CancelablePromise<PageCustomized_Record_Pydantic_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/records',
            headers: {
                'authorization': authorization,
            },
            query: {
                'order_by': orderBy,
                'id': id,
                'reg_uuid': regUuid,
                'name': name,
                'name__icontains': nameIcontains,
                'zone': zone,
                'approved': approved,
                'primary_hostname': primaryHostname,
                'type': type,
                'ttl': ttl,
                'ttl__gte': ttlGte,
                'ttl__lte': ttlLte,
                'value': value,
                'value__icontains': valueIcontains,
                'reg_uuid__isnull': regUuidIsnull,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Record
     * @param requestBody
     * @param authorization
     * @returns Record_Pydantic Successful Response
     * @throws ApiError
     */
    public createRecord(
        requestBody: RecordCreateSchema,
        authorization?: string,
    ): CancelablePromise<Record_Pydantic> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/records',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Record
     * @param recordId
     * @param authorization
     * @returns void
     * @throws ApiError
     */
    public deleteRecord(
        recordId: number,
        authorization?: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/records/{record_id}',
            path: {
                'record_id': recordId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Record
     * @param recordId
     * @param requestBody
     * @param authorization
     * @returns Record_Pydantic Successful Response
     * @throws ApiError
     */
    public updateRecord(
        recordId: number,
        requestBody: RecordUpdateSchema,
        authorization?: string,
    ): CancelablePromise<Record_Pydantic> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/records/{record_id}',
            path: {
                'record_id': recordId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
