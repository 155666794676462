import { Alert, Box, Typography } from '@mui/material';
import {
  ArrayField,
  BooleanField,
  Button,
  CreateButton,
  Datagrid,
  EditButton,
  List,
  NumberField,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';

const personalRegFilters = [
  <TextInput label="pos.search" source="q" alwaysOn />,
];

const PersonalRegList = () => {
  const translation = useTranslate();
  return (
    <>
      <Alert severity="info" sx={{ marginTop: '1rem' }}>
        {translation('resources.alert.unfinished_list_api')}
      </Alert>
      <List filters={personalRegFilters}>
        <Datagrid bulkActionButtons={false} optimized>
          <TextField source="uuid" />
          <TextField source="ipv4" />
          <TextField source="ipv6" />
          <ArrayField source="macs" sortable={false}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="mac" />
              <TextField source="comment" />
              <BooleanField source="dhcp" />
            </Datagrid>
          </ArrayField>
          <BooleanField source="enabled" />
          <TextField source="nextsrv" />
          <TextField source="pxefile" />
          <TextField source="annotation" />
          <NumberField
            source="period_id"
            options={{
              useGrouping: false,
            }}
          />
          <TextField source="schacc" />
          <TextField source="tier" />
          <NumberField
            source="order_id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            source="expected_item_id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            source="actual_item_id"
            options={{
              useGrouping: false,
            }}
          />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default PersonalRegList;
