export const sortFactory = (type: string, field: string) => {
  switch (type) {
    case 'string':
      return sortByString(field);
    case 'number':
      return sortByNumber(field);
    case 'boolean':
      return sortByBoolean(field);
    case 'date':
      return sortByDate(field);
    default:
      return sortByString(field);
  }
};

export const sortByString = (field: string) => {
  return (a: any, b: any) => {
    const fieldA = JSON.stringify(a[field]).toUpperCase();
    const fieldB = JSON.stringify(b[field]).toUpperCase();
    if (fieldA < fieldB) {
      return -1;
    }
    if (fieldA > fieldB) {
      return 1;
    }
    return 0;
  };
};

export const sortByNumber = (field: string) => {
  return (a: any, b: any) => {
    const fieldA = Number(a[field]);
    const fieldB = Number(b[field]);
    if (fieldA < fieldB) {
      return -1;
    }
    if (fieldA > fieldB) {
      return 1;
    }
    return 0;
  };
};

export const sortByBoolean = (field: string) => {
  return (a: any, b: any) => {
    const fieldA = Boolean(a[field]);
    const fieldB = Boolean(b[field]);
    if (fieldA && !fieldB) {
      return -1;
    }
    if (!fieldA && fieldB) {
      return 1;
    }
    return 0;
  };
};

export const sortByDate = (field: string) => {
  return (a: any, b: any) => {
    const fieldA = new Date(a[field]);
    const fieldB = new Date(b[field]);
    if (fieldA < fieldB) {
      return -1;
    }
    if (fieldA > fieldB) {
      return 1;
    }
    return 0;
  };
};
