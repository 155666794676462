import GroupIcon from '@mui/icons-material/Group';
import GroupRegList from './GroupRegList';
import GroupRegEdit from './GroupRegEdit';
import GroupRegCreate from './GroupRegCreate';

export default {
  icon: GroupIcon,
  list: GroupRegList,
  edit: GroupRegEdit,
  create: GroupRegCreate,
};
