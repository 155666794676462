import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress } from '@mui/material';
import {
  Form,
  required,
  useTranslate,
  useLogin,
  useNotify,
  useSafeSetState,
} from 'ra-core';
import { TextInput } from 'react-admin';
import { SubmitHandler, FieldValues } from 'react-hook-form';

export const LoginForm = (props: LoginFormProps) => {
  const { redirectTo, className } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();

  const submit: SubmitHandler<FieldValues> = (values) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'error',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          },
        );
      });
  };

  return (
    <StyledForm
      onSubmit={submit}
      mode="onChange"
      noValidate
      className={className}
    >
      <CardContent className={LoginFormClasses.content}>
        {/* TODO Implement auto login flow */}
        <Button
          variant="contained"
          type="button"
          color="secondary"
          fullWidth
          disabled
          className={LoginFormClasses.button}
          sx={{ marginBottom: '4rem' }}
        >
          {translate('login.sign_in_normal')}
        </Button>
        <TextInput
          autoFocus
          source="jwt"
          validate={required()}
          fullWidth
          sx={{ marginBottom: 0 }}
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress
              className={LoginFormClasses.icon}
              size={19}
              thickness={3}
            />
          ) : (
            translate('login.sign_in_jwt')
          )}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface LoginFormProps {
  redirectTo?: string;
  className?: string;
}

interface FormData {
  username: string;
  password: string;
}
LoginForm.propTypes = {
  redirectTo: PropTypes.string,
};
