import { Button } from '@mui/material';
import { useRecordContext, useRedirect, useTranslate } from 'react-admin';
import { PriceWithId } from './PriceWithId';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const PeriodRedirectButton = () => {
  const record = useRecordContext<PriceWithId>();
  const redirect = useRedirect();
  const translation = useTranslate();
  return (
    <Button
      variant="outlined"
      startIcon={<ArrowBackIosIcon />}
      onClick={() => {
        if (!record || !record.period_id) {
          redirect('/periods');
        } else {
          redirect(`/periods/${record.period_id}`);
        }
      }}
      size="small"
    >
      {translation('resources.prices.redirect_to_period')}
    </Button>
  );
};

export default PeriodRedirectButton;
