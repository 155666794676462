import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import PeriodList from './PeriodList';
import PeriodEdit from './PeriodEdit';
import PeriodCreate from './PeriodCreate';

export default {
  icon: QueryBuilderIcon,
  list: PeriodList,
  edit: PeriodEdit,
  create: PeriodCreate,
};
