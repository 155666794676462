import { Alert, Box, Typography } from '@mui/material';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  EditButton,
  List,
  NumberField,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';

const groupRegFilters = [<TextInput label="pos.search" source="q" alwaysOn />];

const GroupRegList = () => {
  const translation = useTranslate();
  return (
    <>
      <Alert severity="info" sx={{ marginTop: '1rem' }}>
        {translation('resources.alert.unfinished_list_api')}
      </Alert>
      <List filters={groupRegFilters}>
        <Datagrid
          isRowSelectable={() => false}
          optimized
          bulkActionButtons={false}
        >
          <TextField source="uuid" />
          <TextField source="ipv4" />
          <TextField source="ipv6" />
          <ArrayField source="macs" sortable={false}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="mac" />
              <TextField source="comment" />
              <BooleanField source="dhcp" />
            </Datagrid>
          </ArrayField>
          <BooleanField source="enabled" />
          <TextField source="nextsrv" />
          <TextField source="pxefile" />
          <TextField source="annotation" />
          <TextField source="created_by" />
          <ArrayField source="administrators">
            <Datagrid bulkActionButtons={false}>
              <TextField source="schacc" />
            </Datagrid>
          </ArrayField>
          <BooleanField source="approved" />
          <NumberField
            source="period_id"
            options={{
              useGrouping: false,
            }}
          />
          <NumberField
            source="group_id"
            options={{
              useGrouping: false,
            }}
          />
          <TextField source="justification" />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default GroupRegList;
