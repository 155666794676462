import {
  Labeled,
  SimpleForm,
  TextField,
  TextInput,
  Create,
  useTranslate,
  ListButton,
} from 'react-admin';
import { Box, Card, CardContent, FormHelperText, Grid } from '@mui/material';

const ReservedIpAddressCreate = () => {
  const translation = useTranslate();
  return (
    <Create
      title={
        <span>
          {translation('resources.reserved_ip_addresses.create_title')}
        </span>
      }
      component="div"
    >
      <Box maxWidth="50em">
        <Card>
          <ListButton sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
          <SimpleForm>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Labeled source="id">
                    <TextField
                      source="id"
                      fontSize="1.4rem"
                      marginLeft=".6rem"
                      emptyText="Auto generated"
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    source="address"
                    fullWidth
                    helperText="resources.reserved_ip_addresses.fields.address.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    source="comment"
                    fullWidth
                    helperText="resources.reserved_ip_addresses.fields.comment.helperText"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </SimpleForm>
        </Card>
      </Box>
    </Create>
  );
};

export default ReservedIpAddressCreate;
