import {
  SimpleForm,
  Create,
  useTranslate,
  NumberInput,
  SelectInput,
} from 'react-admin';
import { Box, Card, CardContent, Grid } from '@mui/material';
import PeriodRedirectButton from './PeriodRedirectButton';

const PriceCreate = () => {
  const translation = useTranslate();
  return (
    <Create
      title={<span>{translation('resources.prices.create_title')}</span>}
      component="div"
    >
      <Box maxWidth="50em">
        <Card>
          <SimpleForm>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} marginBottom="1rem">
                  <PeriodRedirectButton />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NumberInput
                    source="period_id"
                    isRequired
                    fullWidth
                    helperText="resources.prices.fields.period_id.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <SelectInput
                    margin="none"
                    source="tier"
                    choices={[
                      { id: 'DO', name: 'DO' },
                      { id: 'KB', name: 'KB' },
                      { id: 'AB', name: 'AB' },
                      { id: 'FREE', name: 'FREE' },
                    ]}
                    isRequired
                    fullWidth
                    helperText="resources.prices.fields.tier.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    source="item_id"
                    label="item_id"
                    isRequired
                    fullWidth
                    defaultValue={0}
                    helperText="resources.prices.fields.item_id.helperText"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    source="price"
                    isRequired
                    fullWidth
                    defaultValue={0}
                    helperText="resources.prices.fields.price.helperText"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </SimpleForm>
        </Card>
      </Box>
    </Create>
  );
};

export default PriceCreate;
