import { Alert } from '@mui/material';
import {
  Datagrid,
  EditButton,
  List,
  NumberField,
  TextField,
  useTranslate,
} from 'react-admin';

const ReservedIpAddressList = () => {
  const translation = useTranslate();
  return (
    <>
      <Alert severity="info" sx={{ marginTop: '1rem' }}>
        {translation('resources.alert.unfinished_list_api')}
      </Alert>
      <List pagination={false}>
        <Datagrid
          isRowSelectable={() => false}
          optimized
          bulkActionButtons={false}
        >
          <NumberField
            source="id"
            options={{
              useGrouping: false,
            }}
          />
          <TextField source="address" />
          <TextField source="comment" />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default ReservedIpAddressList;
