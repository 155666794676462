import { DataProvider } from 'ra-core';
import openAPI from '../../services/openapi/openAPI';

export default {
  getList: async (resource, params) => {
    const res = await openAPI.WorkflowV2Service.workflow.list(
      `${params.sort.order === 'DESC' ? '-' : ''}${params.sort.field}`,
      params.filter.id,
      params.filter.state,
      params.filter.type,
      params.filter.stage,
      params.filter.startTime,
      params.filter.endTime,
      params.filter.info,
      true,
      params.pagination.page,
      params.pagination.perPage,
    );

    const data = res.items.map((r) => ({
      ...r,
      info: JSON.stringify(r.info),
    }));

    return {
      data: data,
      total: res.total,
    };
  },

  getOne: async (resource, params) =>
    new Promise(() => {
      console.log('getOne');
    }),

  getMany: async () =>
    new Promise(() => {
      console.log('getMany');
    }),

  getManyReference: async () =>
    new Promise(() => {
      console.log('getManyReference');
    }),

  update: async (resource, params) =>
    new Promise(() => {
      console.log('update');
    }),

  updateMany: async () =>
    new Promise(() => {
      console.log('updateMany');
    }),

  create: async (resource, params) =>
    new Promise(() => {
      console.log('create');
    }),

  delete: async (resource, params) =>
    new Promise(() => {
      console.log('getMany');
    }),

  deleteMany: async () =>
    new Promise(() => {
      console.log('deleteMany');
    }),
} as DataProvider;
